import styled from 'styled-components';
import { breakpoints } from '../../../theme/index';

export const WhySection = styled.section`
    border-top: 5px solid #414345;
    width: 100%;
    height: 100vh;
    padding: 9% 6% 6%;
    position: relative;
    display: flex;
    align-items: center;

    @media ${breakpoints.xsmd} {
        padding: 90px 38px 40px;
        min-height: 100vh;
        height: auto;
        flex-direction: column;
    }

    @media ${breakpoints.md} {
        padding: 150px 38px 40px;
    }
`;

export const WhyTitle = styled.h1`
    width: 47.3vw;
    display: flex;
    flex-direction: column;
    align-items: center;

    @media ${breakpoints.xs} {
        width: auto;
        margin-bottom: 15px;
    }

    &.title {
        line-height: 0.8;
    }

    .title--pink {
        font-size: 9.8vw;

        @media ${breakpoints.xs} {
            font-size: 94px;
        }

        @media ${breakpoints.md} {
            font-size: 12.8vw;
        }
    }

    > span.title--white {
        &:nth-child(2) {
            font-size: 6.2vw;
    
            @media ${breakpoints.xs} {
                font-size: 60px;
            }

            @media ${breakpoints.md} {
                font-size: 8.2vw;
            }
        }
    
        &:nth-child(3) {
            font-size: 9vw;
    
            @media ${breakpoints.xs} {
                font-size: 86px;
            }

            @media ${breakpoints.md} {
                font-size: 12vw;
            }
        }
    }

    .title--turquoise {
        font-size: 10.4vw;

        @media ${breakpoints.xs} {
            font-size:  100px;
        }

        @media ${breakpoints.md} {
            font-size: 13.4vw;
        }
    }

    .separator {
        margin-top: 7px;
        padding: 0 1.9vw;

        @media ${breakpoints.xs} {
            width: 282px;
            margin: 7px 0;
        }

    }
`;

export const WhyParallax = styled.div`
    width: 100%;
    height: 100vh;
    position: absolute;
    left: 0;
    top: 0;
    overflow: hidden;

    @media ${breakpoints.xsmd} {
        height: 100%;
    }

    .why-bg {
        height: 164%;
        width: auto;
        top: -25vh;
        right: -7vh;
        position: absolute;

        @media ${breakpoints.md} {
            height: 131vh;
            top: -9vh;
            left: -17vh;
        }

        @media ${breakpoints.xs} {
            width: 171%;
            height: auto;
            top: -6vh;
            left: -20vh;
        }
    }

    .why-obj {
        width: 26.5%;
        position: absolute;
        top: 24vh;
        right: 0vh;

        @media ${breakpoints.md} {
            display: none;
        }

        @media ${breakpoints.xs} {
            display: none;
        }
    }
`;

export const WhyContent = styled.div`
    width: 100%;
    text-align: right;
    position: relative;
    z-index: 1;

    @media ${breakpoints.xsmd} {
        padding-left: 0;
        display: flex;
        flex-direction: column;
    }

    .why-obj {
        display: none;

        @media ${breakpoints.xsmd} {
            display: block;
            position: relative;
        }

        @media ${breakpoints.md} {
            max-width: 300px;
            width: 25vh;
            left: 32vw;
            top: 3vh;
        }
        
        @media ${breakpoints.xs} {
            max-width: 230px;
            width: 35vh;
            left: 11vw;
            top: 1vh;
        }
    }

    .separator {
        max-width: 57vw;
        margin: 4px 0 0;

        @media ${breakpoints.xsmd} {
            margin: 4px 0 20px;
        }
    }
`;

export const WhyCopy = styled.div`
    margin-top: 40px;
    text-align: left;
    margin-left: 6.5vw;

    @media ${breakpoints.xsmd} {
        margin: 20px 0 0;
        width: 100%;
    }

    h3 {
        font-size: 2.3vw;
        font-weight: 600;

        @media ${breakpoints.xs} {
            font-size: 20px;
        }
    }

    p {
        font-size: 1.9vw;
        margin: 36px 0 0;
        
        @media ${breakpoints.laptopHeight} {
            font-size: 20px;
        }

        @media ${breakpoints.md} {
            margin: 36px auto 0;
            font-size: 18px;
            width: 60%;
        }

        @media ${breakpoints.xs} {
            font-size: 18px;
            margin: 28px 0 0;

            &:first-child {
                margin: 0;
            }
        }

        &:nth-child(1) {
            width: 19.9vw;

            @media ${breakpoints.xs} {
                width: auto;
            }

            @media ${breakpoints.md} {
                width: 60%;
            }
        }
    }
`;