import styled from 'styled-components';
import { breakpoints } from '../../../theme/index';

export const PortfolioSection = styled.section`
    border-top: 5px solid #414345;
    width: 100%;
    height: 100vh;
    padding: 9% 6% 6%;
    position: relative;
    display: flex;
    align-items: center;

    @media ${breakpoints.xsmd} {
        padding: 90px 38px 40px;
        min-height: 100vh;
        height: auto;
        flex-direction: column;
    }

    @media ${breakpoints.md} {
        padding: 150px 38px 40px;
    }
`;

export const PortfolioMainContent = styled.div`
    display: flex;
    flex-direction: column;
`;

export const PortfolioTitle = styled.h1`
    display: flex;
    flex-direction: column;
    width: 30vw;

    @media ${breakpoints.xs} {
        margin-bottom: 15px;
        width: auto;
    }

    @media ${breakpoints.md} {
        margin: 0 auto;
        width: auto;
    }

    &.title {
        line-height: 0.8;
    }

    .title--pink {
        font-size: 5.3vw;

        @media ${breakpoints.xs} {
            font-size: 50px;
        }

        @media ${breakpoints.md} {
            font-size: 8.3vw;
        }
    }

    .title--white {
        font-size: 3.3vw;

        @media ${breakpoints.xs} {
            font-size: 36px;
        }

        @media ${breakpoints.md} {
            font-size: 6.3vw;
        }
    }

    .title--turquoise {
        font-size: 5.7vw;

        @media ${breakpoints.xs} {
            font-size:  40px;
        }

        @media ${breakpoints.md} {
            font-size: 8.7vw;
        }
    }

    .separator {
        margin-top: 7px;
        width: 38vw;

        @media ${breakpoints.xs} {
            width: 67vw;
        }

        @media ${breakpoints.md} {
            width: 60vw;
        }
        
    }
`;

export const PortfolioParallax = styled.div`
    width: 100%;
    height: 100vh;
    position: absolute;
    left: 0;
    top: 0;
    overflow: hidden;

    @media ${breakpoints.xsmd} {
        height: 100%;
    }

    .portfolio-bg {
        height: 100%;
        width: auto;
        top: 11vh;
        right: -7vh;
        position: absolute;

        @media ${breakpoints.md} {
            height: 100vh;
            top: 30vh;
            left: 0vh;
        }

        @media ${breakpoints.xs} {
            width: 171%;
            height: auto;
            top: 10vh;
            left: -16vh;
        }
    }

    .portfolio-obj {
        width: 33.2%;
        position: absolute;
        top: 23vh;
        right: -15vh;

        @media ${breakpoints.md} {
            display: none;
        }

        @media ${breakpoints.xs} {
            display: none;
        }
    }
`;

export const PortfolioContent = styled.div`
    width: 100%;
    text-align: right;
    position: relative;
    z-index: 1;

    @media ${breakpoints.xsmd} {
        padding-left: 0;
        display: flex;
        flex-direction: column;
    }

    .portfolio-obj {
        display: none;

        @media ${breakpoints.xsmd} {
            display: block;
            position: relative;
        }

        @media ${breakpoints.md} {
            max-width: 250px;
            width: 25vh;
            left: 32vw;
            top: 3vh;
        }
        
        @media ${breakpoints.xs} {
            max-width: 226px;
            width: 26vh;
            left: 11vw;
            top: 1vh;
        }
    }

    .separator {
        max-width: 57vw;
        margin: 4px 0 0;

        @media ${breakpoints.xsmd} {
            margin: 4px 0 20px;
        }
    }
`;

export const PortfolioCopy = styled.div`
    margin-top: 40px;
    text-align: left;

    @media ${breakpoints.xsmd} {
        margin: 0;
        width: 100%;
    }

    h3 {
        font-size: 2.3vw;
        font-weight: 600;

        @media ${breakpoints.xs} {
            font-size: 20px;
        }
    }

    p {
        font-size: 1.7vw;
        margin: 40px 0 0;
        width: 63.6vw;

        @media ${breakpoints.xsmd} {
            width: 100%;
        }

        @media ${breakpoints.laptopHeight} {
            font-size: 20px;
        }
        
        @media ${breakpoints.md} {
            margin: 36px auto 0;
            font-size: 18px;
            width: 80%;
        }

        @media ${breakpoints.xs} {
            font-size: 18px;
            margin: 28px 0 0;

            &:first-child {
                // margin: 0;
            }
        }
    }
    
`;

export const PortfolioCopyTitle = styled.div`

    padding-left: 0;
    display: flex;
    flex-direction: column;
    margin-top: 40px;

    @media ${breakpoints.xs} {
        font-size: 27px;
    }

    @media ${breakpoints.md} {
        margin: 36px auto 0;
        width: 80%;
    }


.project-title{
    font-size: 3.3vw;
    font-weight: 900;

    @media ${breakpoints.xs} {
        font-size: 27px;
    }

    @media ${breakpoints.md} {
        font-size: 4vw;
        
    }
}

.separator {
    width: 25vw;
    margin: 4px 0 0;

    @media ${breakpoints.xsmd} {
        width: 170px;
        margin: 4px 0 20px;
    }

}
`;

export const PortfolioCopyDesc = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;

    @media ${breakpoints.xs} {
        flex-direction: column;
    }
`;

export const PortfolioCopyImage = styled.div`

    width: 18vw;
    height: 18vw;
    margin: 40px auto 0;

    @media ${breakpoints.xs} {
        width: 200px;
        height: 200px;
        padding: 10px;
        align-self: center;
        margin: 10px 0 0;
    }

    @media ${breakpoints.md} {
        width: 250px;
        height: 250px;
        margin: 30px 0 0;
    }
`;

export const PortfolioCopyFrame = styled.div`
    height: 100%;

    img {
        height: 100%;
        width: auto;
    }
`;

export const PortfolioCopyInfo = styled.div`
@media ${breakpoints.md} {
    width: 350px;
}

.read-more-btn {
    font-family: 'Open Sans';
	border: none;
    color: black;
    padding: 10px 30px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 20px;
    font-weight: 900;
    cursor: pointer;
    border-radius: 70px;
    background: #2796F3;
}

.read-more-btn:hover {
    background: #D82EB3;
    
    border-radius:25px;
	color: #ECF4FB;
}

.read-more-obj{
    width: 80%;
    margin: 30px auto 0;

    @media ${breakpoints.xs} {
        display: flex;
        width:80%;
        margin: 40px auto 0;
        justify-content: center;
    }
}

.project-desc{
    width: 80%;
    margin: 50px auto 0;

    @media ${breakpoints.md} {
        margin: 40px auto 0;
    }

}


    
`;
