import React, { useEffect } from 'react';
import { WhySection, WhyParallax, WhyContent, WhyTitle, WhyCopy } from './styles';

const Why = () => {

    useEffect(() => {
        const bg = document.querySelector(".why-bg");
        const obj = document.querySelector(".why-obj");
        const objxs = document.querySelector(".why-obj.xs");
        const parallaxArea= document.querySelector('.why-parallax');

        const parallax = (e) => {
            let bgX= (e.clientX * -0.5 / 30);
            let bgY= (e.clientY * -0.5 / 30);
            let objX= (e.clientX * -2 / 30);
            let objY= (e.clientY * -2 / 30);
            let objXsX= (e.clientX * -2 / 30);
            let objXsY= (e.clientY * -2 / 30);

            bg.style.transform = 'translate3d('+bgX+'px,'+bgY+'px, 0)';
            obj.style.transform = 'translate3d('+objX+'px,'+objY+'px, 0)';
            objxs.style.transform = 'translate3d('+objXsX+'px,'+objXsY+'px, 0)';
        }

        parallaxArea.addEventListener("mousemove", parallax);

    });

    return (
        <WhySection className="why-parallax" id="whynfts">

            <WhyTitle className='title'>
                <span className='title--pink'>WHY</span>
                <span className='title--white'>DO YOU</span>
                <span className='title--white'>NEED</span>
                <span className='title--turquoise'>NFTs</span>

                <img className='separator' src='/images/dotted-gradient.svg' alt=''></img>
            </WhyTitle>

            <WhyParallax>
                <img className="why-bg" src='/images/bg-x.png' alt=''></img>
                <img className="why-obj" src='/images/why.png' alt='Pixoken zooming to the rescue with a blue woosh'></img>
            </WhyParallax>

            <WhyContent>
                <img className="why-obj xs" src='/images/why.png' alt='Pixoken zooming to the rescue with a blue woosh'></img>
                
                <WhyCopy>
                    <p>
                    Simple, because NFTs combined with smart contracts can unlock a whole range of experiences and services once thought impossible.
                    </p>

                    <p>
                    Yes, you are thinking correctly, it's like the internet of our age. It comes with unlimited possibilities where only your imagination is the limit.
                    </p>

                    <p>
                    And we at Pixoken have pledged to explore this new market to its core, together we will unveil its awesome benefits.
                    </p>
                </WhyCopy>
            </WhyContent>
        </WhySection>
    );
};

export default Why;