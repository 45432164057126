import React from 'react';
import { FooterContainer } from './styles';
import { Link } from "react-router-dom";

const Footer = () => {

    return (
        <FooterContainer>
            &copy; Pixoken. All Rights Reserved.
            <Link to="/tnc" target="_blank">Terms &amp; Conditions</Link> 
        </FooterContainer>
    );
};

export default Footer;