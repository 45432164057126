import styled from 'styled-components';
import { breakpoints } from '../../../theme/index';

export const FaqSection = styled.section`
    border-top: 5px solid #414345;
    width: 100%;
    height: 100vh;
    padding: 9% 6% 6%;
    position: relative;
    display: flex;
    align-items: center;

    @media ${breakpoints.xs} {
        padding: 100px 38px 40px;
        min-height: 100vh;
        height: auto;
    }
`;

export const FaqColumn = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
`;

export const FaqTitle = styled.h2`
    color: #fff;
    font-size: 8vw;
    display: flex;
    flex-direction: column;

    @media ${breakpoints.xs} {
        font-size: 70px;
        text-align: left;
        margin-bottom: 40px;
    }

    img {
        width: 23vw;
        margin-top: 1vw;
        
        @media ${breakpoints.xs} {
            width: 200px;
            margin-top: 10px;
        }
    }
`;

export const FaqContent = styled.div`

`;

export const FaqTab = styled.ul`
    font-size: 18px;
    display: flex;
    margin-top: 30px;
    margin-left: 50px;

    @media ${breakpoints.xs} {
        margin: 0;
    }

    @media ${breakpoints.md} {
        margin-left: 0;
    }
`;

export const FaqItem = styled.li`
    padding: 15px 40px;
    border-radius: 20px 20px 0 0;
    font-weight: bold;
    cursor: pointer;

    @media ${breakpoints.xs} {
        font-size: 12px;
        text-align: center;
        padding: 15px 10px;
    }

    @media ${breakpoints.md} {
        font-size: 16px;
        padding: 15px 22px;
    }

    &.active {
        background: #23272E;
    }
`;

export const FaqTabContent = styled.div`
    background: #23272E;
    font-size: 16px;
    height: 55vh;
    padding: 30px 20px 30px 50px;

    @media ${breakpoints.xs} {
        padding: 30px 15px 30px 20px;
    }

    > div {
        display: none;
        overflow-y: auto;
        height: 100%;
        padding-right: 50px;

        @media ${breakpoints.xs} {
            padding-right: 10px;
        }

        &.active {
            display: block;
        }

        /* Scrollbar Styling */
        ::-webkit-scrollbar {
            width: 6px;
        }
        
        ::-webkit-scrollbar-track {
            background-color: #99999980;
            border-radius: 10px;
            border: 2px solid #23272E;
        }

        ::-webkit-scrollbar-thumb {
            border-radius: 20px;
            background: #D92EB4; 
        }
    }

    p {
        margin: 0;
        &.question {
            margin: 20px 0 0;
            color: #1AC8ED;
            font-weight: 600;

            &.no-margin {
                margin: 0;
            }
        }
    }
`;

