import styled from 'styled-components';
import { breakpoints } from '../../../../theme/index';

export const MohawkSection = styled.section`
    border-top: 5px solid #414345;
    width: 100%;
    // height: 480vh;
    padding: 9% 6% 6%;
    position: relative;
    display: flex;
    align-items: center;

    @media ${breakpoints.xsmd} {
        padding: 90px 38px 40px;
        min-height: 100vh;
        height: auto;
        flex-direction: column;
    }

    @media ${breakpoints.md} {
        padding: 150px 38px 40px;
        // height: 395vh;
    }
`;

export const MohawkMainContent = styled.div`
    display: flex;
    flex-direction: column;
`;

export const MohawkTitle = styled.h1`
    display: flex;
    flex-direction: column;
    width: 40vw;
    align-items: flex-start;

    @media ${breakpoints.xs} {
        margin-bottom: 15px;
        width: 80%;
    }

    @media ${breakpoints.md} {
        margin: 0 auto;
        width: 80%;
    }

    &.title {
        line-height: 0.8;
    }

    .title--pink {
        font-size: 5.3vw;

        @media ${breakpoints.xs} {
            font-size: 40px;
        }

        @media ${breakpoints.md} {
            font-size: 8.3vw;
        }
    }

    .title--white {
        font-size: 3.3vw;

        @media ${breakpoints.xs} {
            font-size: 5.5vw;
        }

        @media ${breakpoints.md} {
            font-size: 6.3vw;
        }
    }

    .title--turquoise {
        font-size: 5.3vw;

        @media ${breakpoints.xs} {
            font-size: 40px;
        }

        @media ${breakpoints.md} {
            font-size: 8.3vw;
        }
    }

    .separator {
        margin-top: 7px;
        width: 38vw;

        @media ${breakpoints.xs} {
            width: 67vw;
        }

        @media ${breakpoints.md} {
            width: 60vw;
        }
        
    }
`;

export const MohawkParallax = styled.div`
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    overflow: hidden;

    .mohawk-bg {
        height: 100%;
        width: auto;
        top: 20vh;
        left: -70vh;
        position: absolute;

        @media ${breakpoints.md} {
            left: -100vh;
        }

        @media ${breakpoints.xs} {
            left: -150vh;
        }
    }

    .mohawk-obj {
        width: 33.2%;
        position: absolute;
        top: 23vh;
        right: -15vh;

        @media ${breakpoints.md} {
            display: none;
        }

        @media ${breakpoints.xs} {
            display: none;
        }
    }
`;

export const MohawkContent = styled.div`
    width: 100%;
    text-align: right;
    position: relative;
    z-index: 1;

    @media ${breakpoints.xsmd} {
        padding-left: 0;
        display: flex;
        flex-direction: column;
    }

    .mohawk-obj {
        display: none;

        @media ${breakpoints.xsmd} {
            display: block;
            position: relative;
        }

        @media ${breakpoints.md} {
            max-width: 250px;
            width: 25vh;
            left: 32vw;
            top: 3vh;
        }
        
        @media ${breakpoints.xs} {
            max-width: 226px;
            width: 26vh;
            left: 11vw;
            top: 1vh;
        }
    }

    .dotted-line{
        border: none;
        height: 3px;
        background: #fff;
        background: repeating-linear-gradient(90deg,#fff,#fff 6px,transparent 6px,transparent 12px);
        margin: 100px 0 100px;
        height:1px;
        width:100%;
        @media ${breakpoints.xsmd} {
            margin: 60px 0 60px;
        }
    }
`;

export const MohawkCopy = styled.div`
    text-align: left;
    
    @media ${breakpoints.xsmd} {
        margin: 20px 0 20px;
        width: 100%;
    }

    .copy-container{
        display:flex; 
        flex-direction: row;  
        justify-content: space-between;

        @media ${breakpoints.xsmd} {
            flex-direction: column;
            align-items: center;
            
        }
    }
    
    .copy-obj-1{
        width:60%;

        @media ${breakpoints.xsmd} {
            width: 100%;
        }
    }

    .copy-obj-2{
        width:40%;
        display:flex;
        align-items: center;
        justify-content: center;

        @media ${breakpoints.xsmd} {
            margin-top: 20px;
            width: 60%;
        }
    }

    .copy-img{
        width: 80%;

        @media ${breakpoints.xs} {
            width: 250px;
        }
    }

    .xs{
        width: 300px;
        height: auto;

        @media ${breakpoints.xs} {
            width: 200px;
            height: auto;
        }

    }

    .title--white {
        font-size: 3.3vw;

        @media ${breakpoints.xs} {
            font-size: 28px;
        }

        @media ${breakpoints.md} {
            font-size: 6.3vw;
        }
    }

    .copy-desc {
        font-size: 1.7vw;
        margin: 36px 0 36px;
        width: 40vw;

        @media ${breakpoints.xsmd} {
            width: 100%;
        }

        @media ${breakpoints.laptopHeight} {
            font-size: 20px;
        }
        
        @media ${breakpoints.md} {
            margin: 36px auto 0;
            font-size: 18px;
            width: 80%;
        }

        @media ${breakpoints.xs} {
            font-size: 18px;
            margin: 28px 0 0;

            &:first-child {
                margin: 0;
            }
        }
    }

    .author{
        font-style: italic;
    }

    a{
        color: #1AC7EC;
        
    }
`;

export const MohawkSummary =  styled.div`

    display: flex;
    justify-content: space-between;
    flex-direction: row;
    align-items: center;
    margin: 0 0 70px;

    @media ${breakpoints.xsmd} {
        flex-direction: column;
        margin: 0;
    }

    .project-summary{
        flex-direction: column;
        width: 60%;
        @media ${breakpoints.xsmd} {
            width: 100%;
        }

    }

    .video-obj{
        display: flex;
        justify-content: center;
        @media ${breakpoints.xsmd} {
            margin: 36px 0 70px;
        }
    }

    video{
        width: 500px;
        height: auto;
        border: 3px solid white;
        @media ${breakpoints.xs} {
            width: 300px;
            height: auto;
        }
    }


`;

export const MohawkCopyTitle = styled.div`
    padding-left: 0;
    display: flex;
    flex-direction: column;

    @media ${breakpoints.md} {
        margin: 0 auto;
        width: 80%;
    }

    .title--white{
        @media ${breakpoints.xs} {
            font-size: 36px;
        }

        @media ${breakpoints.md} {
            font-size: 5vw;
        }
    }
}

.separator {
    margin-top: 7px;
    width: 38vw;

    @media ${breakpoints.xs} {
        width: 50vw;
    }

    @media ${breakpoints.md} {
        width: 45vw;
    }
}
`;


