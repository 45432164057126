import styled from 'styled-components';
import { breakpoints, color } from '../../../theme/index';

export const MenuContainer = styled.div`
    display: none;
    width: 100%;
    height: 100%;
    background: #11151C;
    position: fixed;
    z-index: 2;
    padding: 16vh 6% 0;

    @media ${breakpoints.xs} {
        padding: 150px 38px 90px;
    }

    @media ${breakpoints.md} {
        padding: 150px 60px 90px;
    }

    &.is-open {
        display: block;
    }
`;

export const MenuList = styled.ul`
    li {
        font-size: 3.1vw;
        line-height: 1.5;
        list-style-type: square;
        margin-left: 20px;
        color: transparent;
        transition: .5s ease-in-out;

        @media ${breakpoints.xs} {
            font-size: 25px;
        }

        @media ${breakpoints.md} {
            font-size: 5.1vw;
        }

        a {
            color: ${color.pink};
            transition: .5s ease-in-out;

            &:hover {
                color: ${color.blue};
            }
        }

        &.active {
            color: ${color.blue};

            a {
                color: ${color.blue};
            }
        }
    }
`;

export const SocialList = styled.ul`
    display: none;

    @media ${breakpoints.xs} {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 50px;
    }

    li {
        padding: 0 10px;
    }

    svg {
        width: 36px;
        fill: #ecf4fb;
    }
`;
