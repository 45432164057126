import styled from 'styled-components';
import { breakpoints, color } from '../../../theme/index';

export const AboutSection = styled.section`
    border-top: 5px solid #414345;
    width: 100%;
    height: 100vh;
    padding: 21vh 6% 6%;
    position: relative;
    display: flex;
    align-items: flex-start;

    @media ${breakpoints.xsmd} {
        padding: 90px 38px 40px;
        height: auto;
        min-height: 100vh;
        align-items: center;
    }
`;

export const AboutParallax = styled.div`
    width: 100%;
    height: 100vh;
    position: absolute;
    left: 0;
    top: 0;
    overflow: hidden;

    @media ${breakpoints.xs} {
        height: 100%;
    }

    .abt-bg {
        height: 165%;
        width: auto;
        top: -27vh;
        right: -14vh;
        position: absolute;

        @media ${breakpoints.xs} {
            width: 98%;
            height: auto;
            bottom: 13vh;
            left: 9vh;
            top: auto;
        }

        @media ${breakpoints.md} {
            width: 77%;
            height: auto;
            bottom: 2vh;
            right: -9vh;
            top: auto;
        }
    }

    .abt-obj-1 {
        width: 18.2%;
        position: absolute;
        bottom: 8vh;
        right: 6.1vw;

        @media ${breakpoints.xsmd} {
            display: none;
        }
    }

    .abt-obj-2 {
        width: 13.9%;
        position: absolute;
        bottom: 11vh;
        right: 16vw;

        @media ${breakpoints.xsmd} {
            display: none;
        }
    }
`;

export const AboutContent = styled.div`
    width: 100%;
    padding-right: 17%;
    text-align: left;
    position: relative;
    z-index: 1;

    @media ${breakpoints.xsmd} {
        padding-right: 0;
        text-align: center;
    }

    .separator {
        max-width: 58vw;

        @media ${breakpoints.xs} {
            max-width: 269px;
            margin-top: 7px;
            display: flex;
            margin: 7px auto;
        }
    }
`;

export const AboutTitle = styled.h1`
    .title {
        line-height: 0.9;
        display: inline-flex;
        flex-direction: column;
    }

    .title--pink {
        font-size: 2.77vw;

        @media ${breakpoints.xs} {
            font-size: 43px;
        }

        @media ${breakpoints.md} {
            font-size: 8.3vw;
        }
    }

    .title--white {
        font-size: 4.4vw;

        @media ${breakpoints.xs} {
            font-size: 68px;
        }

        @media ${breakpoints.md} {
            font-size: 13.3vw;
        }
    }

    .title--turquoise {
        font-size: 8.5vw;
        margin-top: -0.8vw;
        display: flex;

        @media ${breakpoints.xsmd} {
            display: initial;
            margin-top: 0;
        }

        @media ${breakpoints.xs} {
            font-size: 53px;
        }

        @media ${breakpoints.md} {
            font-size: 10.3vw;
        }
    }
`;

export const TitleWrapper = styled.div`
    display: flex;

    @media ${breakpoints.xsmd} {
        flex-direction: column;
    }
`;

export const AboutParallaxXS = styled.div`
    display: none;
    
    @media ${breakpoints.xsmd} {
        display: block;

        img {
            width: 20%;
            position: relative;
        }

        .abt-obj-1 {
            width: 20vh;
            top: 1vh;
        }

        .abt-obj-2 {
            width: 18vh;
            top: 1vh;
        }
    }

    @media ${breakpoints.xs} {
        .abt-obj-1 {
            max-width: 135px;
            left: 90px;
        }

        .abt-obj-2 {
            max-width: 103px;
            left: -92px;
        }
    }

    @media ${breakpoints.md} {
        .abt-obj-1 {
            left: 15vh;
        }

        .abt-obj-2 {
            left: -15vh;
        }
    }

    
`;

export const AboutCopy = styled.div`
    width: 78%;
    margin-top: 40px;

    @media ${breakpoints.xsmd} {
        text-align: left;
        margin: 0 auto;
    }

    @media ${breakpoints.xs} {
        width: 100%;
    }

    @media ${breakpoints.md} {
        width: 70%;
    }

    h3 {
        font-size: 1.9vw;
        font-weight: 600;

        @media ${breakpoints.xsmd} {
            font-size: 18px;
        }
    }

    p {
        font-size: 1.9vw;
        margin: 36px 0 55px;

        @media ${breakpoints.xsmd} {
            margin: 36px 0 25px;
            font-size: 18px;
        }
    }
`;