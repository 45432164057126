import styled from 'styled-components';
import { breakpoints } from '../../../theme/index';

export const FooterContainer = styled.p`
    font-size: 14px;
    padding: 20px 0;
    position: absolute;
    bottom: 0;
    left: 0;
    border-top: 1px solid #ECF4FBCC;
    margin: 0 6%;
    width: 88%;

    @media ${breakpoints.xs} {
        position: static;
        padding: 20px 0;
        margin: 0 38px;
        width: calc(100% - 76px);
    }

    a {
        color: #1AC7EC;
        float: right;
    }
`;