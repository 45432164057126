import styled from 'styled-components';
import { breakpoints } from '../../../theme/index';

export const HomeSection = styled.section`
    width: 100%;
    height: 100vh;
    padding: 9% 6% 6%;
    position: relative;
    display: flex;
    align-items: center;

    @media ${breakpoints.xs} {
        padding: 90px 38px 40px;
    }
`;

export const HomeParallax = styled.div`
    width: 100%;
    height: 100vh;
    position: absolute;
    left: 0;
    top: 0;
    overflow: hidden;

    .home-bg {
        height: 160%;
        width: auto;
        top: -22vh;
        left: -34vh;
        position: absolute;

        @media ${breakpoints.md} {
            height: 131vh;
            top: -9vh;
            left: -17vh;
        }

        @media ${breakpoints.xs} {
            width: 171%;
            height: auto;
            top: -6vh;
            left: -20vh;
        }
    }

    .home-obj {
        width: 20%;
        position: absolute;
        top: 30vh;
        left: 21vh;

        @media ${breakpoints.md} {
            display: none;
        }

        @media ${breakpoints.xs} {
            display: none;
        }
    }
`;

export const HomeTitle = styled.h1`
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    .title--pink {
        font-size: 4.7vw;

        @media ${breakpoints.xs} {
            font-size: 23px;
        }
    }

    .title:nth-child(2) {
        margin-top: 0;
        line-height: 0;


        @media ${breakpoints.xs} {
            font-size: 21px;
        }

        .title--white {
            font-size: 4.5vw;
            width: 13%;
            display: inline-block;
            line-height: 0.9;

            @media ${breakpoints.xs} {
                font-size: 21px;
            }
        }

        .title--blue {
            font-size: 10vw;

            @media ${breakpoints.xs} {
                font-size:  49px;
            }
        }
    }
`;

export const HomeContent = styled.div`
    width: 100%;
    padding-left: 25%;
    text-align: right;
    position: relative;
    z-index: 1;

    @media ${breakpoints.xsmd} {
        padding-left: 0;
        display: flex;
        flex-direction: column;
    }

    .home-obj {
        display: none;

        @media ${breakpoints.xsmd} {
            display: block;
            position: relative;
        }

        @media ${breakpoints.md} {
            max-width: 300px;
            width: 25vh;
            left: 32vw;
            top: 1vh;
        }
        
        @media ${breakpoints.xs} {
            max-width: 194px;
            width: 21vh;
            left: 32vw;
            top: 5vh;
        }
    }

    .separator {
        max-width: 88%;
        margin: 4px 0 0;

        @media ${breakpoints.xsmd} {
            max-width: 282px;
            margin: 4px 0 20px;
        }
    }
`;

export const HomeCopy = styled.div`
    margin-top: 40px;

    @media ${breakpoints.xsmd} {
        text-align: left;
    }

    h3 {
        font-size: 2.3vw;
        font-weight: 600;

        @media ${breakpoints.xs} {
            font-size: 20px;
        }
    }

    p {
        font-size: 2vw;
        margin: 36px 0 0;

        @media ${breakpoints.xs} {
            font-size: 18px;
        }
    }
`;