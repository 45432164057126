import React, { useEffect } from 'react';
import { MagicSection, MagicMainContent, MagicParallax, MagicContent, MagicTitle, MagicCopy } from './styles';

const Magic = () => {

    useEffect(() => {
        const bg = document.querySelector(".magic-bg");
        const obj1 = document.querySelector(".magic-obj-1");
        const obj2 = document.querySelector(".magic-obj-2");
        const obj1xs = document.querySelector(".magic-obj-1.xs");
        const obj2xs = document.querySelector(".magic-obj-2.xs");
        const parallaxArea= document.querySelector('.magic-parallax');

        const parallax = (e) => {
            let bgX= (e.clientX * -0.5 / 30);
            let bgY= (e.clientY * -0.5 / 30);
            let obj1X= (e.clientX * -1 / 30);
            let obj1Y= (e.clientY * -1 / 30);
            let obj2X= (e.clientX * -0.8 / 30);
            let obj2Y= (e.clientY * -0.8 / 30);
            let obj1XsX= (e.clientX * -1 / 30);
            let obj1XsY= (e.clientY * -1 / 30);
            let obj2XsX= (e.clientX * -0.8 / 30);
            let obj2XsY= (e.clientY * -0.8 / 30);

            bg.style.transform = 'translate3d('+bgX+'px,'+bgY+'px, 0)';
            obj1.style.transform = 'translate3d('+obj1X+'px,'+obj1Y+'px, 0)';
            obj2.style.transform = 'translate3d('+obj2X+'px,'+obj2Y+'px, 0)';
            obj1xs.style.transform = 'translate3d('+obj1XsX+'px,'+obj1XsY+'px, 0)';
            obj2xs.style.transform = 'translate3d('+obj2XsX+'px,'+obj2XsY+'px, 0)';
        }

        parallaxArea.addEventListener("mousemove", parallax);

    });

    return (
        <MagicSection className="magic-parallax" id="themagic">

            <MagicParallax>
                <img className="magic-bg" src='/images/bg-magic.png' alt=''></img>
                <img className="magic-obj-1" src='/images/magic-1.png' alt='Captain overwatch and keyboard warrior sitting at a desk searching for NFTs'></img>
                <img className="magic-obj-2" src='/images/magic-2.png' alt='Captain overwatch and keyboard warrior sitting at a desk searching for NFTs'></img>
            </MagicParallax>

            <MagicMainContent>

                <MagicTitle>
                    <div className='title'>
                        <span className='title--pink'>HOW</span>
                        <span className='title--white'>DO<br className='hide--xs hide--md'></br> WE<br className='hide--xs hide--md'></br> MAKE</span>
                    </div>
                    <div className='title'>
                        <span className='title--turquoise'>MAGIC</span>
                        <span className='title--white'>HAPPEN<br className='hide--xs hide--md'></br> FOR<br className='hide--xs hide--md'></br> YOU?</span>
                    </div>

                    <img className='separator' src='/images/dotted-gradient.svg' alt=''></img>
                </MagicTitle>

                <MagicContent>
                    <img className="magic-obj-1 xs" src='/images/magic-1.png' alt='Captain overwatch and keyboard warrior sitting at a desk searching for NFTs'></img>
                    <img className="magic-obj-2 xs" src='/images/magic-2.png' alt='Captain overwatch and keyboard warrior sitting at a desk searching for NFTs'></img>
                    
                    <MagicCopy>
                        <p>A sprinkle here, a sprinkle there, the pixoken magic is in all our work</p>
                        <p><b className='title--turquoise'>Designing</b> - We design the highest quality artwork covering a range of styles and themes tailored to your requirements.</p>
                        <p><b className='title--turquoise'>Creation</b>- We have advanced coding experience across the front end, back end, and smart contract development.</p>
                        <p><b className='title--turquoise'>Marketing</b> - We run a highly effective digital marketing campaign across multiple social media accounts.</p>
                        <p><b className='title--turquoise'>Launch</b> - We help you launch NFTs on the market places or through a dedicated website with crypto wallet integration.</p>
                    </MagicCopy>
                </MagicContent>

            </MagicMainContent>
            
        </MagicSection>
    );
};

export default Magic;