import styled from 'styled-components';
import { breakpoints } from '../../../theme/index';

export const TeamSection = styled.section`
    border-top: 5px solid #414345;
    width: 100%;
    height: 100vh;
    padding: 9% 6% 6%;
    position: relative;
    display: flex;
    align-items: center;

    @media ${breakpoints.xs} {
        padding: 140px 38px 40px;
        min-height: 100vh;
        height: auto;
    }
`;

export const TeamContent = styled.div`
    width: 100%;
    max-width: 52vw;
    text-align: center;
    position: relative;
    z-index: 1;
    margin: 0 auto;

    @media ${breakpoints.xs} {
        max-width: initial;
        text-align: center;
    }
`;

export const MemberRow = styled.div`
    display: flex;
    justify-content: center;
    margin-bottom: 5vw;

    @media ${breakpoints.xs} {
        flex-direction: column;
    }

    &.show--md {
        display: none;

        @media ${breakpoints.md} {
            display: flex;
        }
    }

    &:last-child {
        margin: 0;
    }
`;

export const TeamMember = styled.div`
    width: auto;
    margin-right: 3vw;

    @media ${breakpoints.xs} {
        margin-right: 0;
    }

    &:last-child {
        margin-right: 0;
    }
`;

export const MemberTitle = styled.div`
    display: flex;
    flex-direction: column;
    text-align: left;
    margin-bottom: 12px;

    @media ${breakpoints.xs} {
        text-align: center;
        margin-bottom: 24px;
    }

    .title--pink {
        font-size: 3.3vw;

        @media ${breakpoints.md} {
            font-size: 29px;
        }

        @media ${breakpoints.xs} {
            font-size: 43px;
        }
        

        @media ${breakpoints.xxs} {
            font-size: 38px;
        }
    }

    .title--turquoise {
        font-size: 2.5vw;

        @media ${breakpoints.xs} {
            font-size: 35px
        }

        @media ${breakpoints.md} {
            font-size: 20px;
        }
    }
`;

export const MemberDetails = styled.div`
    display: flex;

    @media ${breakpoints.xs} {
        flex-direction: column;
    }
`;

export const MemberImage = styled.div`
    border: 3px solid #fff;
    width: 10.2vw;
    height: 12.7vw;
    padding: 0.52vw;
    margin-right: 20px;

    @media ${breakpoints.xs} {
        width: 193px;
        height: 242px;
        padding: 10px;
        align-self: center;
        margin-right: 0;
    }

    @media ${breakpoints.md} {
        width: 103px;
        height: 152px;
    }
`;

export const MemberFrame = styled.div`
    border: 1px solid #fff;
    padding-bottom: 15px;
    padding-top: 20px;
    height: 100%;

    img {
        height: 100%;
        width: auto;
    }
`;

export const MemberCopy = styled.div`

`;

export const Barcode = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;

    @media ${breakpoints.xs} {
        margin-top: 16px;
        justify-content: center;
    }

    svg {
        margin-right: 9px;

        @media ${breakpoints.md} {
            width: 55px;
        }
    }
`;

export const Code = styled.div`
    display: flex;
    font-size: 1.1vw;
    flex-direction: column;
    text-transform: uppercase;

    @media ${breakpoints.xs} {
        font-size: 20px;
    }

    @media ${breakpoints.md} {
        font-size: 14px;
    }

    > p {
        margin: 0;
    }
`;

export const Description = styled.p`
    font-size: 1.1vw;
    width: 13.5vw;
    text-align: left;

    @media ${breakpoints.xs} {
        font-size: 18px;
        width: auto;
        margin: 20px 12px 50px;
    }

    @media ${breakpoints.md} {
        font-size: 16px;
        width: 23.5vw;
        margin: 0;

        &.last {
            width: 38.5vw;
        }
    }
`;
