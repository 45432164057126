import React, { useEffect } from 'react';
import { MohawkSection, MohawkMainContent, MohawkParallax, MohawkContent, MohawkTitle, MohawkSummary, MohawkCopy, MohawkCopyTitle } from './styles';
const MohawkNFT = () => {

    useEffect(() => {
        const bg = document.querySelector(".mohawk-bg");
        const obj = document.querySelector(".mohawk-obj");
        const objxs = document.querySelector(".mohawk-obj.xs");
        const parallaxArea= document.querySelector('.mohawk-parallax');

        const parallax = (e) => {
            let bgX= (e.clientX * -0.5 / 30);
            let bgY= (e.clientY * -0.5 / 30);
            let objX= (e.clientX * -2 / 30);
            let objY= (e.clientY * -2 / 30);
            let objXsX= (e.clientX * -2 / 30);
            let objXsY= (e.clientY * -2 / 30);

            bg.style.transform = 'translate3d('+bgX+'px,'+bgY+'px, 0)';
            obj.style.transform = 'translate3d('+objX+'px,'+objY+'px, 0)';
            objxs.style.transform = 'translate3d('+objXsX+'px,'+objXsY+'px, 0)';
        }

        parallaxArea.addEventListener("mousemove", parallax);
        window.scrollTo(0, 0)
         });


    return ( 
    <MohawkSection className="mohawk-parallax" id="mohawk">
            <MohawkParallax>
                <img className="mohawk-bg" src='../../images/bg-mohawk.png' alt=''></img>
            </MohawkParallax>

        <MohawkMainContent>
            <MohawkContent>
                <MohawkSummary>
                    <div className="project-summary">
                        <MohawkTitle className='title'>
                            <div>
                                <span className='title--pink'>MOHAWK</span><span className='title--turquoise'>NFT</span>
                            </div>
                            <span className='title--white'>PROJECT SUMMARY</span>
                            
                            <img className='separator' src='../../images/dotted-gradient.svg' alt=''></img>
                        </MohawkTitle>
                        
                        <MohawkCopy>
                            <p className="copy-desc"> 
                                MohawkNFT was created for Chris J. “Mohawk” Reed as an entry point for 
                                anyone wanting to enhance their personal brand – whether that be through becoming a thought leader, 
                                a top selling author, an entrepreneur or a LinkedIn guru! <br></br><br></br>
                                Pixoken provided F2B services to design, create and build the NFT series, included bespoken branding,
                                website design and development, front end integration and smart contract development on the Ethereum network.
                            </p>
                        </MohawkCopy>
                    </div>

                    <div className="video-obj">
                        <video controls>
                            <source src="../../videos/MohawkNFTs NEW.mp4" type="video/mp4"/>
                        </video>
                    </div>
                    
                </MohawkSummary>

                <MohawkCopy>
                    <div className="copy-container">
                        <div className="copy-obj-1">
                            <MohawkCopyTitle className='title'>
                                <span className='title--white'>PROJECT MANAGEMENT</span>
                                <img className='separator' src='../../images/dotted-gradient.svg' alt=''></img>
                            </MohawkCopyTitle>
                            <p className="copy-desc"> 
                                Pixoken was able to effectively project manage a complete
                                F2B MohawkNFT build on time and within costs. Managing the different delivery 
                                components was critical for the successful delivery of MohawkNFT. 
                                With 12+ years of project management experience, Pixoken is well placed to manage both simple and complex projects.
                            </p>
                        </div>

                        <div className="copy-obj-2">
                            <img className='copy-img xs' src="../../images/mohawk/mohawk-project-management.png" alt="Mohawk NFT"></img>
                        </div>
                    </div>
                </MohawkCopy>

                <hr className="dotted-line"></hr>

                <MohawkCopy>
                    <div className="copy-container">
                        <div className="copy-obj-1">
                            <MohawkCopyTitle className='title'>
                                <span className='title--white'>BRANDING</span>
                                <img className='separator' src='../../images/dotted-gradient.svg' alt=''></img>
                            </MohawkCopyTitle>
                            <p className="copy-desc"> 
                                Pixoken created the MohawkNFT branding and colour scheme, 
                                which then set the tone for the artwork style and website theme.
                            </p>
                        </div>

                        <div className="copy-obj-2">
                            <img className='copy-img' src="../../images/mohawk/mohawk-branding.png" alt="Mohawk NFT"></img>
                        </div>
                    </div>

                </MohawkCopy>

                <hr className="dotted-line"></hr>

                <MohawkCopy>
                        <div className="copy-container">
                            <div className="copy-obj-1">

                                <MohawkCopyTitle className='title'>
                                    <span className='title--white'>NFT ARTWORK</span>
                                    <img className='separator' src='../../images/dotted-gradient.svg' alt=''></img>
                                </MohawkCopyTitle>

                                <p className="copy-desc"> 
                                    Pixoken created a multi-layer psd ﬁle for each of the unique attributes and 
                                    then combined them together using a python script to generate 10,000 unique MohawkNFTs. 
                                    All MohawkNFTs are stored on IPFS, a decentralized storage solution.
                                </p>
                            </div>

                            <div className="copy-obj-2">
                                <img className='copy-img' src="../../images/mohawk/mohawk-example-artwork.png" alt="Mohawk NFT"></img>
                            </div>
                        </div>
                </MohawkCopy>

                <hr className="dotted-line"></hr>

                <MohawkCopy>
                    
                    <div className="copy-container">
                        <div className="copy-obj-1">

                            <MohawkCopyTitle className='title'>
                                <span className='title--white'>WEBSITE AND SMART CONTRACT</span>
                                <img className='separator' src='../../images/dotted-gradient.svg' alt=''></img>
                            </MohawkCopyTitle>

                            <p className="copy-desc"> 
                            Pixoken create a bespoke website, with animation and front end integration with MetaMask 
                            (using deeplinks) and our own minting smart contract. The smart contract 
                            was also developed to be compatible with Opensea for 
                            easy access to the re-sell market. Users can purchase 
                            MohawkNFTs directly from www.mohawkNFT.io and 
                            then see them appear on Opensea within a few minutes!
                            </p>
                        </div>

                        <div className="copy-obj-2">
                            <img className='copy-img' src="../../images/mohawk/mohawk-website.png" alt="Mohawk NFT"></img>
                        </div>
                    </div>
                </MohawkCopy>

                <hr className="dotted-line"></hr>

                <MohawkCopy>
                    <div className="copy-container">
                    <div className="copy-obj-1">
                            <MohawkCopyTitle className='title'>
                                <span className='title--white'>ADVERTISING & PROMOTIONAL MATERIAL</span>
                                <img className='separator' src='../../images/dotted-gradient.svg' alt=''></img>
                            </MohawkCopyTitle>

                            <p className="copy-desc"> 
                            Pixoken created promotional material for Chris J. “Mohawk” Reed to use on his LinkedIn profile. 
                            The material covered training sessions, competitions, go-live dates, launch day, banners and other graphical work.
                            </p>
                        </div>

                        <div className="copy-obj-2">
                            <img className='copy-img' src="../../images/mohawk/mohawk-ads.png" alt="Mohawk NFT"></img>
                        </div>
                    </div>
                </MohawkCopy>

                <hr className="dotted-line"></hr>

                <MohawkCopy>

                    <div className="copy-container">
                    <div className="copy-obj-1">
                            <MohawkCopyTitle className='title'>
                                <span className='title--white'>CLIENT FEEDBACK</span>
                                <img className='separator' src='../../images/dotted-gradient.svg' alt=''></img>
                            </MohawkCopyTitle>
                            <p className="copy-desc"> 
                                “It was a joy working with Pixoken to create MohawkNFT!
                                From the get go, the whole experience was seamless 
                                and exciting. Being new to the NFT space, Pixoken helped 
                                explain everything clearly and eﬀectively. I would have 
                                no hesitation in choosing Pixoken again for future NFT 
                                projects, which is important since I have over 105,000 
                                followers on Linkedin” 
                            </p>
                            <span className="author">- Chris J. “Mohawk” Reed, CEO of <a href="https://blackmarketing.com/">Black Marketing</a></span>

                        </div>

                        <div className="copy-obj-2">
                            <img className='copy-img' src="../../images/mohawk/mohawk-feedback.png" alt="Mohawk NFT"></img>
                        </div>
                    </div>
                </MohawkCopy>
            </MohawkContent>

        </MohawkMainContent>
        
    </MohawkSection>
    )
};

export default MohawkNFT;