import React, { useEffect } from 'react';
import { NftsSection, NftsMainContent, NftsParallax, NftsContent, NftsTitle, NftsCopy } from './styles';

const Nfts = () => {

    useEffect(() => {
        const bg = document.querySelector(".nfts-bg");
        const obj = document.querySelector(".nfts-obj");
        const objxs = document.querySelector(".nfts-obj.xs");
        const parallaxArea= document.querySelector('.nfts-parallax');

        const parallax = (e) => {
            let bgX= (e.clientX * -0.5 / 30);
            let bgY= (e.clientY * -0.5 / 30);
            let objX= (e.clientX * -2 / 30);
            let objY= (e.clientY * -2 / 30);
            let objXsX= (e.clientX * -2 / 30);
            let objXsY= (e.clientY * -2 / 30);

            bg.style.transform = 'translate3d('+bgX+'px,'+bgY+'px, 0)';
            obj.style.transform = 'translate3d('+objX+'px,'+objY+'px, 0)';
            objxs.style.transform = 'translate3d('+objXsX+'px,'+objXsY+'px, 0)';
        }

        parallaxArea.addEventListener("mousemove", parallax);

    });

    return (
        <NftsSection className="nfts-parallax">

            <NftsParallax>
                <img className="nfts-bg" src='/images/bg-x.png' alt=''></img>
                <img className="nfts-obj" src='/images/need-nft.png' alt='Pixoken is look out for you'></img>
            </NftsParallax>

            <NftsMainContent>

                <NftsTitle className='title'>
                    <span className='title--pink'>AND IT'S</span>
                    <div className='second-title'>
                        <span className='title--turquoise'>NOT</span>
                        <span className='title--white'>EASY</span>
                    </div>
                    

                    <img className='separator' src='/images/dotted-gradient.svg' alt=''></img>
                </NftsTitle>

                <NftsContent>
                    <img className="nfts-obj xs" src='/images/need-nft.png' alt='Pixoken is look out for you'></img>
                    
                    <NftsCopy>
                        <p>But creating an NFT is not a one-step process. It requires designing, creating, marketing, launching, and more!</p>
                        <p>Now the problem is who can provide all these services to you?</p>
                        <p>We can! </p>
                        <p>You can't afford to be left high and dry in the middle of nowhere with an inexperienced team.</p>
                        <p>Hence, we do all your work in-house. We provide all the services required to create a great, revenue-generating NFT.</p>
                        <p>Let's look at them.</p>
                    </NftsCopy>
                </NftsContent>

            </NftsMainContent>
            
        </NftsSection>
    );
};

export default Nfts;