import { createGlobalStyle } from "styled-components";
import { breakpoints } from '../theme/index';

const GlobalStyle = createGlobalStyle`

.hide {
    display: none;

    &--xsmd {
        @media ${breakpoints.xsmd} {
            display: none;
        }
    }

    &--xs {
        @media ${breakpoints.xs} {
            display: none;
        }
    }

    &--md {
        @media ${breakpoints.md} {
            display: none;
        }
    }

    &--lg {
        @media ${breakpoints.lg} {
            display: none;
        }
    }
}

.title {
    font-family: 'Montserrat', sans-serif;
    font-weight: 900;
    line-height: 1;

    &--pink {
        color: #D82EB3;
    }

    &--white {
        color: #ECF4FB;
    }

    &--blue {
        color: #2796F3;
    }

    &--turquoise {
        color: #1AC7EC
    }
}
`;

export default GlobalStyle;