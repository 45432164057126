import styled from 'styled-components';
import { breakpoints } from '../../../theme/index';

export const MeetTheTeamSection = styled.section`
    border-top: 5px solid #414345;
    width: 100%;
    height: 100vh;
    padding: 9% 6% 6%;
    position: relative;
    display: flex;
    align-items: center;

    @media ${breakpoints.xs} {
        padding: 150px 38px 40px;
    }
`;

export const MeetTheTeamParallax = styled.div`
    width: 100%;
    height: 100vh;
    position: absolute;
    left: 50%;
    top: 50%;
    overflow: hidden;
    background: url(/images/bg-x.png) center -31vh no-repeat;
    background-size: 138vh;
    transform: translate(-50%, -50%);

    @media ${breakpoints.xsmd} {
        background: url(/images/bg-x.png) 67% 34% no-repeat;
        background-size: 170%;
    }
`;

export const MeetTheTeamTitle = styled.h1`
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    line-height: 0.8 !important;

    @media ${breakpoints.xs} {
        align-items: center;
    }

    .title--white {
        font-size: 3vw;
        width: 100%;
        text-align: left;

        @media ${breakpoints.xs} {
            font-size: 45px;
            text-align: center;
        }
    }

    .title--pink {
        font-size: 8.1vw;

        @media ${breakpoints.xs} {
            font-size: 42px;
        }
    }

    .title--turquoise {
        font-size: 12.1vw;

        @media ${breakpoints.xs} {
            font-size:  95px;
        }
    }

    .separator {
        max-width: 37vw;
        margin: 4px 8px;

        @media ${breakpoints.xs} {
            width: 293px;
            max-width: initial;
        }
    }
`;

export const MeetTheTeamContent = styled.div`
    width: 100%;
    max-width: 52vw;
    text-align: center;
    position: relative;
    z-index: 1;
    margin: 0 auto;

    @media ${breakpoints.xs} {
        max-width: initial;
        text-align: center;
    }
`;

export const MeetTheTeamCopy = styled.div`
    margin-bottom: 90px;
    
    @media ${breakpoints.xsmd} {
        margin-bottom: 54px;
    }

    h3 {
        font-size: 1.9vw;
        font-weight: 600;

        @media ${breakpoints.xsmd} {
            font-size: 18px;
        }
    }

    span {
        font-weight: 900;
    }
`;