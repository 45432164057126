import styled from 'styled-components';
import { breakpoints } from '../../../theme/index';

export const HeaderContainer = styled.header`
    position: fixed;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 3% 6%;
    z-index: 2;
    top: 0;

    @media ${breakpoints.xs} {
        justify-content: center;
        padding: 38px 0 0;
    }
    
    > div {
        display: flex;
        align-items: center;
    }
`;

export const HeaderLeft = styled.div`
    display: flex;
    align-items: center;

    .logo {
        @media ${breakpoints.xs} {
            max-width: 137px;
        }
    }
`;

export const BurgerBar = styled.div`
    width: 44px;
    height: 24px;
    position: relative;
    transform: rotate(0deg);
    transition: .5s ease-in-out;
    cursor: pointer;
    margin-right: 20px;

    &:hover {
        span {
            width: 44px !important;
        }
    }

    &.open {
        span {
            &:nth-child(1) {
                transform: rotate(45deg);
                top: -8px;
                left: -8px;
                width: 44px;

                @media ${breakpoints.xs} {
                    left: 0;
                }
            }

            &:nth-child(2) {
                width: 0%;
                opacity: 0;
            }

            &:nth-child(3) {
                transform: rotate(-45deg);
                top: 24px;
                left: -8px;
                width: 44px;
                background: #2796F3;

                @media ${breakpoints.xs} {
                    left: 0;
                }
            }
        }
    }

    span {
        display: block;
        position: absolute;
        height: 5px;
        opacity: 1;
        right: 0;
        transform: rotate(0deg);
        transition: .25s ease-in-out;

        @media ${breakpoints.xs} {
            height: 3px;
        }

        &:nth-child(1) {
            top: 0px;
            transform-origin: left center;
            width: 34px;
            background: #D92EB4;
        }

        &:nth-child(2) {
            top: 9px;
            transform-origin: left center;
            background: #2796F3;
            width: 38px;
        }

        &:nth-child(3) {
            top: 18px;
            transform-origin: left center;
            background: #fff;
            width: 44px;
        }
    }
`;

export const SocialList = styled.ul`
    display: flex;
    align-items: center;

    @media ${breakpoints.xs} {
        display: none;
    }

    li {
        padding: 0 10px;
    }

    svg {
        width: 36px;
        fill: #ecf4fb;
    }
`;