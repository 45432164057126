import styled from 'styled-components';
import { breakpoints } from '../../../theme/index';

export const NftsSection = styled.section`
    border-top: 5px solid #414345;
    width: 100%;
    height: 100vh;
    padding: 9% 6% 6%;
    position: relative;
    display: flex;
    align-items: center;

    @media ${breakpoints.xsmd} {
        padding: 90px 38px 40px;
        min-height: 100vh;
        height: auto;
        flex-direction: column;
    }

    @media ${breakpoints.md} {
        padding: 150px 38px 40px;
    }
`;

export const NftsMainContent = styled.div`
    display: flex;
    flex-direction: column;
`;

export const NftsTitle = styled.h1`
    display: flex;
    flex-direction: column;
    width: 30vw;

    @media ${breakpoints.xs} {
        margin-bottom: 15px;
        width: auto;
    }

    @media ${breakpoints.md} {
        margin: 0 auto;
        width: 50vw;
    }

    .second-title {
        display: flex;
        align-items: flex-end;
        width: fit-content;

        @media ${breakpoints.xs} {
            flex-direction: column;
        }
    }

    &.title {
        line-height: 0.8;
    }

    .title--pink {
        font-size: 2.8vw;

        @media ${breakpoints.xs} {
            font-size: 34px;
        }

        @media ${breakpoints.md} {
            font-size: 5.8vw;
        }
    }

    .title--white {
        font-size: 2.8vw;
        margin-left: -1vw;
        line-height: 1;

        @media ${breakpoints.xs} {
            font-size: 34px;
        }

        @media ${breakpoints.md} {
            font-size: 5.8vw;
        }
    }

    .title--turquoise {
        font-size: 9.7vw;

        @media ${breakpoints.xs} {
            font-size:  121px;
        }

        @media ${breakpoints.md} {
            font-size: 12.7vw;
        }
    }

    .separator {
        margin-top: 7px;
        width: 98%;

        @media ${breakpoints.xs} {
            width: 284px;
        }
    }
`;

export const NftsParallax = styled.div`
    width: 100%;
    height: 100vh;
    position: absolute;
    left: 0;
    top: 0;
    overflow: hidden;

    @media ${breakpoints.xsmd} {
        height: 100%;
    }

    .nfts-bg {
        height: 164%;
        width: auto;
        top: -25vh;
        right: -7vh;
        position: absolute;

        @media ${breakpoints.md} {
            height: 131vh;
            top: -9vh;
            left: -17vh;
        }

        @media ${breakpoints.xs} {
            width: 171%;
            height: auto;
            top: -6vh;
            left: -20vh;
        }
    }

    .nfts-obj {
        width: 17.5%;
        position: absolute;
        top: 22vh;
        right: 12vh;

        @media ${breakpoints.md} {
            display: none;
        }

        @media ${breakpoints.xs} {
            display: none;
        }
    }
`;

export const NftsContent = styled.div`
    width: 100%;
    text-align: right;
    position: relative;
    z-index: 1;

    @media ${breakpoints.xsmd} {
        padding-left: 0;
        display: flex;
        flex-direction: column;
    }

    .nfts-obj {
        display: none;

        @media ${breakpoints.xsmd} {
            display: block;
            position: relative;
        }

        @media ${breakpoints.md} {
            max-width: 250px;
            width: 25vh;
            left: 32vw;
            top: 3vh;
        }
        
        @media ${breakpoints.xs} {
            max-width: 178px;
            width: 27vh;
            left: 11vw;
            top: 1vh;
        }
    }

    .separator {
        max-width: 57vw;
        margin: 4px 0 0;

        @media ${breakpoints.xsmd} {
            margin: 4px 0 20px;
        }
    }
`;

export const NftsCopy = styled.div`
    margin-top: 40px;
    text-align: left;

    @media ${breakpoints.xsmd} {
        margin: 20px 0 0;
        width: 100%;
    }

    h3 {
        font-size: 2.3vw;
        font-weight: 600;

        @media ${breakpoints.xs} {
            font-size: 20px;
        }
    }

    p {
        font-size: 1.9vw;
        margin: 36px 0 0;

        @media ${breakpoints.laptopHeight} {
            font-size: 20px;
        }
        
        @media ${breakpoints.md} {
            margin: 36px auto 0;
            font-size: 18px;
            width: 80%;
        }

        @media ${breakpoints.xs} {
            font-size: 18px;
            margin: 28px 0 0;

            &:first-child {
                margin: 0;
            }
        }

        &:nth-child(1) {
            width: 65.9vw;

            @media ${breakpoints.xs} {
                width: auto;
            }

            @media ${breakpoints.md} {
                width: 80%;
            }
        }
    }
`;