import React, { useEffect } from 'react';
import { AboutSection, AboutParallax, AboutContent, AboutTitle, AboutCopy, TitleWrapper, AboutParallaxXS } from './styles';
import gsap from 'gsap';

const WhatWeDo = () => {

    useEffect(() => {
        const bg = document.querySelector(".abt-bg");
        const obj1 = document.querySelector(".abt-obj-1");
        const obj2 = document.querySelector(".abt-obj-2");
        const parallaxArea= document.querySelector('.abt-parallax');

        const parallax = (e) => {
            let bgX= (e.clientX * -0.2 / 30);
            let bgY= (e.clientY * -0.2 / 30);
            let obj1X= (e.clientX * -0.5 / 30);
            let obj1Y= (e.clientY * -0.5 / 30);
            let obj2X= (e.clientX * -1 / 30);
            let obj2Y= (e.clientY * -1 / 30);

            bg.style.transform = 'translate3d('+bgX+'px,'+bgY+'px, 0)';
            obj1.style.transform = 'translate3d('+obj1X+'px,'+obj1Y+'px, 0)';
            obj2.style.transform = 'translate3d('+obj2X+'px,'+obj2Y+'px, 0)';
        }

        parallaxArea.addEventListener("mousemove", parallax);

    });

    return (
        <AboutSection className="abt-parallax" id="whatwedo">
            <AboutParallax>
                <img className="abt-bg" src='/images/bg-exactly.png' alt=''></img>
                <img className="abt-obj-1" src='/images/exactly-2.png' alt='Mini Pixokens painting Mona Lisa as an NFT'></img>
                <img className="abt-obj-2" src='/images/exactly-1.png' alt='Mini Pixokens painting Mona Lisa as an NFT'></img>
            </AboutParallax>
            <AboutContent>
                <AboutTitle>
                    <TitleWrapper>
                        <div className='title'>
                            <span className='title--pink'>WHAT IT IS </span>
                            <span className='title--white'>WE DO</span>
                        </div>
                        <div className='title'>
                            <span className='title--turquoise'>EXACTLY</span>
                        </div>
                    </TitleWrapper>
                    <img className='separator' src='/images/dotted-gradient.svg' alt=''></img>
                </AboutTitle>
                <AboutParallaxXS>
                    <img className="abt-obj-1 xs" src='/images/exactly-2.png' alt='Mini Pixokens painting Mona Lisa as an NFT'></img>
                    <img className="abt-obj-2 xs" src='/images/exactly-1.png' alt='Mini Pixokens painting Mona Lisa as an NFT'></img>
                </AboutParallaxXS>
                <AboutCopy>
                    <p>We help communities, businesses, and individuals understand the value of NFTs, create new and sustainable revenue streams while increasing user engagement experiences. </p>
                    <h3>Isn't it great?</h3>

                </AboutCopy>
            </AboutContent>
        </AboutSection>
    );
};

export default WhatWeDo;