import styled from 'styled-components';
import { breakpoints } from '../../../theme/index';

export const ContactSection = styled.section`
    border-top: 5px solid #414345;
    width: 100%;
    height: 100vh;
    padding: 9% 6% 6%;
    position: relative;
    display: flex;
    align-items: center;

    @media ${breakpoints.xs} {
        padding: 150px 38px 40px;
        min-height: 100vh;
        height: auto;
    }
`;

export const ContactColumn = styled.div`
    display: flex;
    flex-direction: column;
`;

export const ContactTitle = styled.h2`
    color: #fff;
    font-size: 3.2vw;

    @media ${breakpoints.xs} {
        font-size: 20px;
        text-align: right;
    }
`;

export const Logo = styled.div`
    width: 55vw;

    @media ${breakpoints.xs} {
        width: 100%;
        margin-bottom: 40px;
    }
`;

export const ContactContent = styled.div`
    display: flex;
    font-size: 1.9vw;
    margin-top: 3.8vw;

    @media ${breakpoints.xsmd} {
        font-size: 26px;
        flex-direction: column;
    }

    @media ${breakpoints.laptopHeight} {
        font-size: 20px;
    }
`;

export const ContactList = styled.ul`
    margin-right: 6.5vw;

    @media ${breakpoints.xsmd} {
        margin-right: 0;
        margin-bottom: 60px;
    }
`;

export const ContactItem = styled.li`
    @media ${breakpoints.xsmd} {
        margin-bottom: 30px;
    }

    a {
        color: #2796F3;
        font-weight: bold;
    }
`;

export const Address = styled.div`
    p {
        margin-bottom: 0;
    }
`;