import React from 'react';
import { MeetTheTeamSection, MeetTheTeamParallax, MeetTheTeamContent, MeetTheTeamTitle, MeetTheTeamCopy } from './styles';

const MeetTheTeam = () => {

    return (
        <MeetTheTeamSection className="" id="meettheteam">

            <MeetTheTeamParallax></MeetTheTeamParallax>

            <MeetTheTeamContent>
                <MeetTheTeamCopy>
                    <h3>And don't worry I am not a one-man Army, <br className='hide--lg'></br><br className='hide--lg hide--md'></br> We have an <span>experienced</span> team of Pixokens for all this,</h3>
                </MeetTheTeamCopy>

                <MeetTheTeamTitle className='title'>
                    <span className='title--white'>LET ME</span>
                    <span className='title--pink'>INTRODUCE</span>
                    <span className='title--turquoise'>THEM</span>
                    <img className='separator' src='/images/dotted-gradient.svg' alt=''></img>
                </MeetTheTeamTitle>
            </MeetTheTeamContent>
        </MeetTheTeamSection>
    );
};

export default MeetTheTeam;