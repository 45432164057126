import React from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import ReactDOM from 'react-dom';
import reportWebVitals from './reportWebVitals';
import './index.css';
import Header from './components/common/Header';
import Home from './components/section/Home';
import WhatWeDo from './components/section/WhatWeDo';
import MeetTheTeam from './components/section/MeetTheTeam';
import Team from './components/section/Team';
import Why from './components/section/Why';
import GlobalStyle from './theme/GlobalStyle';
import Nfts from './components/section/NFTs';
import Magic from './components/section/Magic';
import Portfolio from './components/section/Portfolio';
import MohawkNFT from './components/section/Portfolio/MohawkNFT';
import Faq from './components/section/Faq';
import Contact from './components/section/Contact';
import Tnc from './components/section/Tnc';
import Footer from './components/common/Footer';

ReactDOM.render(
  <BrowserRouter>
    <GlobalStyle />
    <Header />
    <Routes>
      <Route path="/" 
        element={
          <>
            <Home />
            <WhatWeDo />
            <MeetTheTeam />
            <Team />
            <Why />
            <Nfts />
            <Magic />
            <Portfolio />
            <Faq />
            <Contact />
          </>
        } 
      />
      <Route path="/mohawknft" element={ <MohawkNFT/> }/>
      <Route path="tnc" element={<Tnc/>} />
    </Routes> 
    <Footer />
  </BrowserRouter>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();