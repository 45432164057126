import React, { useState } from 'react';
import { FaqSection, FaqColumn, FaqTitle, FaqContent, FaqTab, FaqTabContent, FaqItem } from './styles';

const Faq = () => {
    const [activeTab, setActiveTab] = useState(1);
    const changeTab = (tabNum) => {
        setActiveTab(tabNum)
    };

    return (
        <FaqSection className="" id="faq">
            <FaqColumn>
                <FaqTitle className="title">
                    <span className='title--pink'>FAQs</span>
                    <img className='separator' src='/images/dotted-gradient.svg' alt=''></img>
                </FaqTitle>

                <FaqContent>
                    <FaqTab>
                        <FaqItem className={activeTab === 1 ? 'active' : ''} onClick={() => changeTab(1)}>
                            What is an NFT
                        </FaqItem>
                        <FaqItem className={activeTab === 2 ? 'active' : ''} onClick={() => changeTab(2)}> 
                            How to Buy NFTs?
                        </FaqItem>
                        <FaqItem className={activeTab === 3 ? 'active' : ''} onClick={() => changeTab(3)}> 
                            Benefits of NFTs
                        </FaqItem>
                    </FaqTab>
                    <FaqTabContent>
                        <div className={activeTab === 1 ? 'active' : ''} >
                            <p className='question no-margin'>What does NFT stand for?</p>
                            <p>NFT stands for non-fungible token. This means it cannot be exchanged for another item that is exactly the same, because by definition it is unique - only one of a kind.</p>

                            <p className='question'>How is an NFT created?</p>
                            <p>At its simplest an NFT is any unique digital asset, such as a visual, audio or utility asset, that has been minted onto a blockchain. There are different types of blockchains, but the most popular one is the Ethereum network</p>
                            
                            <p className='question'>What does minting mean?</p>
                            <p>Minting means the unique digital asset has been recorded onto the blockchain. Think of it like writing to a hard disk drive, except the hard disk drive is a decentralized network called a blockchain. Once written to the blockchain it can never be deleted...ever!</p>

                            <p className='question'>What gets recorded onto the blockchain?</p>
                            <p>Specific information about the unique digital asset, now an NFT, is written onto the blockchain by a smart contract. The smart contract will define and point to the storage location of the NFT, its attributes as well as other information about the owner and creator. The smart contract allows, with the owners permission, interaction with the NFT through the blockchain</p>

                            <p className='question'>Can anyone change the information on the blockchain?</p>
                            <p>No. That's the amazing thing about NFTs and blockchains, once minted, the details of the NFT cannot be changed. Only the ownership can change, by selling or buying the NFT, which then updates the owner details on the blockchain via a smart contract</p>

                            <p className='question'>Where are NFTs stored?</p>
                            <p>This is a good question and is an easy way to spot legitimate NFT projects from suspect projects. Since the NFT itself is not on the blockchain (only the location is) its important that nobody can tamper with the location the NFT is stored in. That's why it is important to choose a tamper proof storage solution like IPFS</p>

                            <p className='question'>What is IPFS?</p>
                            <p>IPFS stands for Inter Planetary File System (cool name!). It is a decentralized storage solution. It uses Distributed Hash Tables (DHT) to form a file location. If anyone changes the files in the file location the DHT will change, meaning nobody can replace or alter the NFT without someone knowing. Previous versions can always be restored in such an event</p>
                            
                            <p className='question'>Can't i just copy an NFT for free?</p>
                            <p>This is the big misconception of NFTs. You can copy the NFT (right click and save!), but you cannot copy the ownership. The ownership is tied to the crypto wallet of the owner. Just like taking a photo of the Mona Lisa, it does not equate to owning it</p>

                            <p className='question'>Summary</p>
                            <p>An NFT is a unique digital asset that has been minted onto a blockchain. The minting process writes certain information onto the blockchain to identify the digital asset, its owner and creator.<br></br><br></br>
                            High quality NFT projects will store the digital asset and attributes on a decentralized storage solution like IPFS. This protects the owner of the NFT from having their digital asset swapped out, for another digital asset, without their knowledge or a recover mechanism.<br></br><br></br>
                            You cant copy the ownership of an NFT, which is where the true value of the NFT resides.</p>
                        </div>

                        <div className={activeTab === 2 ? 'active' : ''} >
                            <p>Before we begin... Please remember this is not financial advice and is only intended for educational purposes only.</p>

                            <p className='question'>How to buy an NFT?</p>
                            <p>To buy an NFT you will need a crypto currency or digital payment token for the blockchain that the NFT is written on. The most common blockchain is Ethereum and the currency it uses is ether (ETH). There are others, such as Solana, Polygon, Cardano etc. However Ethereum is the biggest and most widely used blockchain</p>

                            <p className='question'>How to buy Ether?</p>
                            <p>To buy ether (ETH) you need to join a crypto exchange. There are many crypto exchanges, but choose one that is approved in your country and / or  jurisdiction. Once you have joined a crypto exchange you can exchange fiat currency (USD, SGD, GBP etc) for any crypto currency. For example in this case ether (ETH)</p>

                            <p className='question'>How to get Ether into a crypto wallet?</p>
                            <p>Once you have ether (ETH) you need to make sure it is in a supported crypto wallet for the website or app you want to use to buy NFTs. Some exchanges have their own wallets, like Coinbase Wallet, which many websites support. However a lot of exchanges do not. There are many crypto wallets to choose from, a widely supported crypto wallet (for NFTs) is MetaMask. Pixoken uses MetaMask integration for all our NFT launches.</p>

                            <p className='question'>How to get a MetaMask Wallet?</p>
                            <p>Please go to https://metamask.io or google metamask.io and follow their excellent guide on setting up your crypto wallet</p>

                            <p className='question'>How to transfer ether (ETH) into your MetaMask wallet?</p>
                            <p>It is easy to transfer ether (ETH) into your MetaMask wallet. To transfer your ether (ETH) from your exchange to your MetaMask wallet, you need to know the crypto wallet address of your MetaMask wallet. It is extremely important that you use the correct address without any missing characters. Please refer to the MetaMask.io website to determine your crypto wallet address. Then follow your crypto exchange process to send ether (ETH) from your exchange into your MetaMask wallet using your crypto wallet address.</p>

                            <p className='question'>I have ether (ETH) in my wallet, now what?</p>
                            <p>Now you have ether (ETH) in your MetaMask wallet, your all setup to buy NFTs! You can either use marketplaces like Opensea or you can buy directly off websites during their pre-launch sales. Pixoken does its own pre-launch sales of NFTs for clients and fully integrates with MetaMask wallet. This lets you buy NFTs before they are on marketplaces like Opensea. To buy from Pixoken sites you simply click the “buy now” button during the pre-launch events. This will trigger a smart contract to exchange ether (ETH) for one of the NFTs.</p>

                            <p className='question'>Summary</p>
                            <p>To buy or sell any NFT you need a crypto currency for the blockchain that your NFT is on, for Ethereum this is ether (ETH).
                            <br></br><br></br>
                            To buy ether (ETH) you need to be a member of a crypto exchange that is approved in your country and / or  jurisdiction. This allows you to buy ether (ETH) in exchange for fiat currencies (USD, GBP, SGD etc).
                            <br></br><br></br>
                            Once you have ether (ETH) you need a supported crypto wallet, like MetaMask, to hold your ether (ETH) and interact with smart contracts. The same crypto wallet will also hold your NFTs.
                            <br></br><br></br>  
                            Once you have your crypto wallet you are all set and can buy/sell NFTs on marketplaces or specific NFT project websites
                            <br></br><br></br>  
                            Important: Ensure you do your research before buying into any NFT project, make sure they are legitimate, speak to a financial adviser and adhere to any rules or regulations in your country and / or jurisdiction. Crypto assets are volatile and can increase and decrease in value quickly</p>
                        </div>

                        <div className={activeTab === 3 ? 'active' : ''} >
                            <p>The NFT space is still very young and growing exponentially. For businesses, getting into NFTs early has some significant benefits tied to innovation, digitalisation and creativity.<br></br><br></br>
                            NFTs can open up exciting customer engagement channels, while creating new and sustainable revenue streams.
                            <br></br><br></br>
                            The critical elements to a successful NFT is a clear reason to exist and a community or client base that will benefit.
                            <br></br><br></br>
                            NFTs combined with smart contracts can unlock a whole range of services once thought impossible.
                            <br></br><br></br>
                            Examples of possible NFTs:</p>

                            <p className='question'>Community membership NFTs: </p>
                            <p>If you have a community, a charity or a cause that needs promoting, NFTs are an excellent mechanism to do that. By designing unique pieces of art, such as digital avatars, your community can increase its exposure to new members through the creation of NFTs. People that believe in your cause or are interested in your cause will be drawn to your artwork to discover more. If they buy your NFT they can then gain access to the community and take an active role in helping it grow and achieve its aims</p>

                            <p className='question'>Customer NFTs: </p>
                            <p>Similar to community membership, NFTs can give people exclusive benefits or access to products, individuals or companies. The buyer is buying into a reason, a status, a following or an image. Think of entrepreneurs who want access to investors, fans who want access to celebrities and retailers who want to attract new customers etc. By selling NFTs, brands and individuals are opening up new and sustainable revenue streams to audiences who are willing to invest their time and money to match their aspirational objectives. This kind of collaboration has not been possible before</p>

                            <p className='question'>Security NFTs:</p>
                            <p>NFTs give the holders access to services, locations, or digital spaces. Organizations can use this capability to ensure only holders get access to secure locations. Think physical memorabilia stored in a warehouse on behalf of an NFT owner. The NFT gives only the holder access to the physical memorabilia</p>

                            <p className='question'>Tourism NFTs:</p>
                            <p>Tourism is a massive industry generating billions in revenue every year. Countries spend huge amounts on advertising to attract tourists and try to direct them to certain areas of the country. Imagine a world where you arrive in a country and you are issued with a randomly generated NFT. The NFT could then give you certain benefits like discounts or free tickets to certain events in certain location. The tourism agency can then manage the flow of tourists throughout the country in a new and more engaging way.</p>

                            <p>All these examples are just the tip of the iceberg, the user cases of NFTs are limitless.</p>
                        </div>
                    </FaqTabContent>
                </FaqContent>
            </FaqColumn>
        </FaqSection>
    );
};

export default Faq;