import React from 'react';
import { ContactSection, ContactColumn, ContactTitle, Logo, ContactContent, ContactList, ContactItem, Address, Footer } from './styles';

const Contact = () => {

    return (
        <ContactSection className="" id="contact">
            <ContactColumn>
                <ContactTitle className="title">CONTACT</ContactTitle>
                <Logo><img className="" src='/images/logo-contact.png' alt=''></img></Logo>
                <ContactContent>
                    <ContactList>
                        <ContactItem>Send us an email <a href='mailto:hello@pixoken.com'>hello@pixoken.com</a></ContactItem>
                        <ContactItem>Reach us on twitter<a href='https://twitter.com/Pixoken_nfts' target="_blank"> @pixoken_nfts</a></ContactItem>
                        <ContactItem>Reach us on Instagram <a href='https://www.instagram.com/pixoken_nfts/' target="_blank">@pixoken_nfts</a></ContactItem>
                        {/* <ContactItem>Telephone xxx xxx xxx</ContactItem> */}
                    </ContactList>
                    <Address>
                        <h3>Where are we based?</h3>
                        <p>1 Coleman Street <br></br>
                        #10-06 The Adelphi<br></br>
                        Singapore<br></br>
                        179803</p>
                    </Address>
                </ContactContent>
            </ContactColumn>
            
        </ContactSection>
    );
};

export default Contact;