import styled from 'styled-components';
import { breakpoints } from '../../../theme/index';

export const MagicSection = styled.section`
    border-top: 5px solid #414345;
    width: 100%;
    height: 100vh;
    padding: 9% 6% 6%;
    position: relative;
    display: flex;
    align-items: center;

    @media ${breakpoints.xsmd} {
        padding: 90px 38px 40px;
        min-height: 100vh;
        height: auto;
        flex-direction: column;
    }

    @media ${breakpoints.md} {
        padding: 150px 38px 40px;
    }
`;

export const MagicMainContent = styled.div`
    display: flex;
    flex-direction: column;
`;

export const MagicTitle = styled.h1`
    display: flex;
    flex-direction: column;
    width: 30vw;

    @media ${breakpoints.xs} {
        margin-bottom: 15px;
        width: auto;
    }

    @media ${breakpoints.md} {
        margin: 0 auto;
        width: 50vw;
    }

    .title {
        display: flex;
        align-items: flex-end;
        width: fit-content;
        line-height: 0.8;

        @media ${breakpoints.xsmd} {
            flex-direction: column;
            align-items: flex-start;
        }
    }
    .title--pink {
        font-size: 4.8vw;

        @media ${breakpoints.xs} {
            font-size: 56px;
        }

        @media ${breakpoints.md} {
            font-size: 10.8vw;
        }
    }

    .title--white {
        font-size: 1.3vw;
        line-height: 1;
        margin-left: 0.2vw;

        @media ${breakpoints.xs} {
            font-size: 23px;
        }

        @media ${breakpoints.md} {
            font-size: 6.3vw;
        }
    }

    .title--turquoise {
        font-size: 6.2vw;

        @media ${breakpoints.xs} {
            font-size:  71px;
        }

        @media ${breakpoints.md} {
            font-size: 12.2vw;
        }

        + .title--white {
            font-size: 1.6vw;

            @media ${breakpoints.xs} {
                font-size:  19px;
            }

            @media ${breakpoints.md} {
                font-size: 4.6vw;
            }
        }
    }

    .separator {
        margin-top: 7px;

        @media ${breakpoints.xs} {
            width: 262px;
        }
    }
`;

export const MagicParallax = styled.div`
    width: 100%;
    height: 100vh;
    position: absolute;
    left: 0;
    top: 0;
    overflow: hidden;

    @media ${breakpoints.xsmd} {
        height: 100%;
    }

    .magic-bg {
        height: 113%;
        width: auto;
        top: 8vh;
        right: -28vh;
        position: absolute;
    
        @media ${breakpoints.md} {
            width: 120%;
            height: auto;
            top: 26vh;
            left: -6vh;
        }

        @media ${breakpoints.xs} {
            width: 149%;
            height: auto;
            top: 15vh;
            left: -10vh;
        }
    }

    .magic-obj-1 {
        width: 5.5%;
        position: absolute;
        top: 10vw;
        right: 19vw;

        @media ${breakpoints.md} {
            display: none;
        }

        @media ${breakpoints.xs} {
            display: none;
        }
    }

    .magic-obj-2 {
        width: 12.5%;
        position: absolute;
        top: 9vw;
        right: 7vw;

        @media ${breakpoints.md} {
            display: none;
        }

        @media ${breakpoints.xs} {
            display: none;
        }
    }
`;

export const MagicContent = styled.div`
    width: 100%;
    text-align: right;
    position: relative;
    z-index: 1;

    @media ${breakpoints.xsmd} {
        padding-left: 0;
        display: flex;
        flex-direction: column;
    }

    .magic-obj-1 {
        display: none;

        @media ${breakpoints.xsmd} {
            display: block;
            position: absolute;
        }

        @media ${breakpoints.md} {
            max-width: 114px;
            width: 25vh;
            left: 19vw;
            top: 3vh;
        }
        
        @media ${breakpoints.xs} {
            max-width: 87px;
            width: 27vh;
            left: 7vw;
            top: 1vh;
        }
    }

    .magic-obj-2 {
        display: none;

        @media ${breakpoints.xsmd} {
            display: block;
            position: relative;
        }

        @media ${breakpoints.md} {
            max-width: 250px;
            width: 25vh;
            left: 32vw;
            top: 3vh;
        }
        
        @media ${breakpoints.xs} {
            max-width: 178px;
            width: 27vh;
            left: 26vw;
            top: 1vh;
        }
    }

    .separator {
        max-width: 57vw;
        margin: 4px 0 0;

        @media ${breakpoints.xsmd} {
            margin: 4px 0 20px;
        }
    }
`;

export const MagicCopy = styled.div`
    margin-top: 40px;
    text-align: left;

    @media ${breakpoints.xsmd} {
        margin: 20px 0 0;
        width: 100%;
    }

    h3 {
        font-size: 2.3vw;
        font-weight: 600;

        @media ${breakpoints.xs} {
            font-size: 20px;
        }
    }

    p {
        font-size: 1.9vw;
        margin: 36px 0 0;

        @media ${breakpoints.laptopHeight} {
            font-size: 20px;
        }

        @media ${breakpoints.md} {
            margin: 36px auto 0;
            font-size: 18px;
            width: 80%;
        }

        @media ${breakpoints.xs} {
            font-size: 18px;
            margin: 20px 0 0;

            &:first-child {
                margin: 0;
            }
        }

        &:nth-child(1) {
            width: 65.9vw;

            @media ${breakpoints.xs} {
                width: auto;
            }

            @media ${breakpoints.md} {
                width: 80%;
            }
        }
    }
`;