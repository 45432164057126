import React, { useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { PortfolioSection, PortfolioMainContent, PortfolioParallax, PortfolioContent, PortfolioTitle, PortfolioCopy, PortfolioCopyTitle, PortfolioCopyDesc, PortfolioCopyFrame, PortfolioCopyImage, PortfolioCopyInfo } from './styles';

const Portfolio = () => {

    useEffect(() => {
        const bg = document.querySelector(".portfolio-bg");
        const obj = document.querySelector(".portfolio-obj");
        const objxs = document.querySelector(".portfolio-obj.xs");
        const parallaxArea= document.querySelector('.portfolio-parallax');

        const parallax = (e) => {
            let bgX= (e.clientX * -0.5 / 30);
            let bgY= (e.clientY * -0.5 / 30);
            let objX= (e.clientX * -2 / 30);
            let objY= (e.clientY * -2 / 30);
            let objXsX= (e.clientX * -2 / 30);
            let objXsY= (e.clientY * -2 / 30);

            bg.style.transform = 'translate3d('+bgX+'px,'+bgY+'px, 0)';
            obj.style.transform = 'translate3d('+objX+'px,'+objY+'px, 0)';
            objxs.style.transform = 'translate3d('+objXsX+'px,'+objXsY+'px, 0)';
        }

        parallaxArea.addEventListener("mousemove", parallax);

    });

    const navigate = useNavigate();

    return (
        <PortfolioSection className="portfolio-parallax" id="portfolio">

            <PortfolioParallax>
                <img className="portfolio-bg" src='/images/bg-portfolio.png' alt=''></img>
            </PortfolioParallax>

            <PortfolioMainContent>
                <PortfolioTitle className='title'>
                    <span className='title--pink'>PORTFOLIO</span>

                    <img className='separator' src='/images/dotted-gradient.svg' alt=''></img>
                </PortfolioTitle>

                <PortfolioContent>
                    {/* <img className="portfolio-obj xs" src='/images/portfolio.png' alt='Mouthpiece echo loving pixoken portfolio of previously created NFT'></img> */}
                
                    <PortfolioCopy> 
                    <p>
                        We are working with some very exciting clients on some amazing NFTs and can’t show their work until it gets finished.
                        But do take a look below at some of our earlier projects that have gone live!
                        Watch out for more mind-boggling and earth-shattering stuff, coming soon, under this section.
                    </p>
                        <PortfolioCopyTitle>
                            <span className='project-title'>MOHAWKNFT</span>
                            <img className='separator' src='/images/dotted-gradient.svg' alt=''></img>
                        </PortfolioCopyTitle>
                        
                        <PortfolioCopyDesc>
                            <PortfolioCopyImage>
                                <PortfolioCopyFrame>
                                    <img className='project-obj xs' src="/images/mohawk/mohawk-example-artwork.png" alt="Mohawk NFT"></img>
                                </PortfolioCopyFrame>
                            </PortfolioCopyImage>

                            <PortfolioCopyInfo>
                                <p className="project-desc">
                                    Pixoken created a multi-layer psd ﬁle for each of 
                                    the unique attributes and then combined them together using a 
                                    python script to generate 10,000 unique MohawkNFTs. 
                                    All MohawkNFTs are stored on IPFS, a decentralized...
                                </p>
                                <div className="read-more-obj">
                                    <button type="button" className="read-more-btn" onClick={() => navigate("mohawknft")}> READ MORE </button>
                                </div>
                            </PortfolioCopyInfo>

                        </PortfolioCopyDesc>
                    </PortfolioCopy>
                </PortfolioContent>

            </PortfolioMainContent>
            

        
        </PortfolioSection>
    );
};

export default Portfolio;