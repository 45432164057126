import React from 'react';
import { TncSection, TncColumn, TncTitle, TncContent } from './styles';

const Tnc = () => {
    return (
        <TncSection className="">
            <TncColumn>
                <TncTitle className="title">
                    <span className='title--pink'>WEBSITE TERMS AND CONDITIONS OF USE</span>
                </TncTitle>

                <TncContent>
                    <div>
                        <p className='question no-margin'>The Site is owned and operated by Pixoken. The following Conditions govern your use and/or access of the Site. By using or accessing the Site, you are deemed to have accepted and agreed to be bound by these Conditions. It is your responsibility to ensure that you have read and understood these Conditions and any accompanying risks, obligations and responsibilities.</p>
                        <p>
                            <b>
                            Please read these terms of use carefully before you start to use and/or access the Site. If you do not agree to any part of these Conditions, please do not use and/or access the Site.
                            </b>
                        </p>

                        <ol>
                            <li>DEFINITIONS AND INTERPRETATION
                                <ol>
                                    <li>
                                        <span><b>Definitions</b></span>
                                        <p><b>"Conditions"</b> : The terms and conditions contained herein and any amendments from time to time, as published on the Site.</p>
                                        <p><b>"Personal Data"</b> : Data, whether true or not, that can be used to identify, contact or locate you. Personal Data can include your name, e-mail address, billing address, shipping address, phone number and credit card information.</p>
                                        <p><b>"Pixoken"</b>, <b>"we"</b>, <b>"our"</b> and <b>"us"</b> : Pixoken Pte. Ltd.</p>
                                        <p><b>"Site"</b> : Both the mobile and web versions of the website located at www.pixoken.com.</p>
                                    </li>
                                    <li>
                                        <span><b>Interpretation</b></span>
                                        <p>(a) The headings in these Conditions are inserted for convenience only and shall not affect the interpretation of these Conditions.</p>
                                        <p>(b) Unless the context otherwise requires, words importing the singular shall include the plural and vice versa and words importing a specific gender shall include the other genders (male, female or neuter).</p>
                                        <p>(c) Any reference to conduct includes without limitation, an omission, statement or undertaking, whether or not in writing.</p>
                                    </li>
                                </ol>
                            </li>

                            <li>ACCESS AND USE OF THE SITE
                                <ol>
                                    <li>
                                        <span><b>Terms of Use.</b> By using and/or accessing the Site, you hereby agree that:</span>
                                        <p>(a) you will not copy or distribute any part of the Site in any medium without our prior written authorisation; and</p>
                                        <p>(b) you will not use the Site for any purpose that is unlawful or prohibited by these Conditions, or to solicit the performance of any illegal activity or other activity which infringes our rights or the rights of others. Notwithstanding any other rights or restrictions in these Conditions, you may not use the Site to: (i) transmit via or through the Site any information, data, text, images, files, links or software except in connection with your authorised use of this Site or otherwise in response to specific requests for information by us, (ii) introduce to the Site or any other computer or website viruses, worms, Trojan horses and/or harmful code, (iii) obtain unauthorised access to any computer system, (iv) impersonate any other person or falsely state or otherwise misrepresent your affiliation with any person or entity, (v) invade the privacy or violate any personal or proprietary right (including intellectual property rights) of any person or entity, (vi) misrepresent the identity of a user or use a false e-mail address, (vii) tamper with or obtain access to the Site or any component of the Site, (viii) conduct fraudulent activities, or (ix) collect or harvest information regarding other users of the Site for any reason whatsoever, including, without limitation, for sending such users unsolicited commercial e-mail.</p>
                                        <p><b>Links to Third Party Sites.</b> The Site may contain links to third party websites that are not affiliated with or owned, operated, or controlled by us, including third party payment system providers. You acknowledge and agree that we are not responsible for the content, privacy policies, or practices of such third party websites or the companies that own them. By using the Site, you expressly relieve us from any and all liability arising from your access and use of any third party website.</p>
                                    </li>
                                </ol>
                            </li>

                            <li>CHANGES TO THE SITE
                                <p>We may alter, suspend, or discontinue this Site in whole or in part, at any time and for any reason, without notice or cost. We may, in our sole discretion, terminate or suspend your use of or access to all or part of the Site for any reason, including without limitation, breach of these Conditions. Any of the material on the Site may be out of date at any given time, and we are under no obligation to update such material.</p>
                            </li>

                            <li>
                            TRANSACTIONS CONCLUDED THROUGH THE SITE
                            <p>Contracts for the supply of goods, services or products formed through the Site or as a result of visits made by you to the Site are governed by the specific terms and conditions of supply for that good, service or product, as highlighted to you prior to any contract being formed.</p>
                            </li>

                            <li>
                            LIMITATION OF LIABILITY
                                <ol>
                                    <li>
                                        <span><b>No Representations or Warranties.</b> The Site, its content, services and all text, images, merchandise, and other information provided herein are provided on an "as is" and "as available" basis without representations or warranties of any kind, whether express, implied or statutory, including but not limited to warranties of title or implied warranties of merchantability, satisfactory quality, fitness for a particular purpose or non-infringement.<br></br><br></br>
                                        Without limitation to the generality of the foregoing, we expressly disclaim any warranty, condition, guarantee, term or representation (a) as to the reliability, accuracy, completeness, and validity of any content or material on the Site, (b) that the functions contained on the Site will be secure, uninterrupted or free of errors, (c) that any defects will be corrected, or (d) that the Site or the server(s) that makes it available are free of viruses or other harmful components. Any and all such warranties, conditions, terms and representations are specifically excluded. We assume no responsibility for errors or omissions in the materials on the Site, including factual or other inaccuracies or typographical errors. You expressly agree that your use and/or access of the Site is at your sole risk.
                                        </span>
                                    </li>

                                    <li>
                                        <span><b>No Liability for Indirect or Consequential Losses.</b> To the maximum extent permitted under applicable law, we shall not be liable to any user of the Site or any other person for any direct, indirect, special or consequential loss or damage (including, but not limited to, damages for loss of profits, loss of data or loss of use) arising out of or related to the use, inability to use, performance or failure of this Site or any materials posted therein, or any information contained therein or stored or maintained by us, irrespective of whether such damages were foreseeable or arise in contract, tort, equity, restitution, by statute, at common law or otherwise.</span>
                                    </li>

                                    <li>
                                        <span><b>Third Party Service Providers.</b> You acknowledge and agree that your access and use of the Site is dependent on third party service providers such as internet, network, connectivity or other link providers. Your payment for any transactions or contracts concluded by you for the supply of goods or services formed through the Site is processed by third party payment system providers and we do not retain or process any such payment information. We cannot guarantee the security of such third party payment system(s) or any payment data on the Site. We are not responsible for any acts or omissions of any third parties and disclaim any and all liability in connection with the acts, omissions or defaults of such third parties. Without prejudice to the generality of the foregoing, we are not responsible for the results of any credit inquiry, the availability or performance of the Internet, your connection to the Internet or the actions or inaction of any other person or entity, including any internet service provider. By using and/or accessing the Site, you expressly relieve us from any and all liability in connection with the acts, omissions or defaults of such third parties.</span>
                                    </li>

                                    <li>
                                        <span><b>Liability Implied by Law.</b> For the avoidance of doubt, nothing in this Condition 5 excludes, restricts or modifies any condition, warranty, right or liability implied into these Conditions where to do so is illegal or would render any provision hereof void.</span>
                                    </li>
                                </ol>
                            </li>

                            <li>
                            INDEMNITY
                            <p>By using and/or accessing the Site, you agree to indemnify, hold harmless and defend us and our partners, affiliates, subsidiaries, advertising agencies, agents and their employees, officers, directors, and representatives from any claims, losses, damages, liabilities, costs and/or expenses, including but not limited to attorneys' fees, arising directly or indirectly from your access of and/or use of this Site, including without limitation, any claims of infringement of a third party's rights, any assertion and enforcement of our rights under these Conditions and any breaches of your obligations under these Conditions.</p>
                            </li>

                            <li>
                                INTELLECTUAL PROPERTY RIGHTS
                                <ol>
                                    <li>
                                        <span><b>Ownership.</b> The intellectual property rights in and to the content on the Site, and in the material published on it are owned, licensed to or controlled by us, our licensors or our service providers. We reserve the right to enforce these intellectual property rights to the fullest extent of the law.</span>
                                    </li>

                                    <li>
                                        <span><b>Restricted Use.</b> You may not copy, reproduce, modify, republish, upload, post, transmit, adapt, download, distribute or howsoever deal with any content or material from the Site in any form or by any means without our prior written permission, and you are solely responsible for obtaining</span>
                                    </li>
                                </ol>
                            </li>

                            <li>
                                PRIVACY POLICY
                                <ol>
                                    <li>
                                        <span>You are not required to provide personal information to us, although in some cases if you choose not to do so then we will be unable to make certain sections of the Site available to you. For example, we may need to have your contact information in order to provide you with updates from the Site.</span>
                                    </li>

                                    <li>
                                        <span>When you provide personal information to us, we will comply with applicable privacy and data protection laws.</span>
                                    </li>

                                    <li>
                                        <span>
                                        The personal information you provide to us (including any information provided if you register for an account) is collected and may be used for communicating with you, statistical analysis, the marketing by us of products and services to you, credit checks (if necessary), and research and development.
                                        </span>
                                    </li>

                                    <li>
                                        <span>We may also collect technical information whenever you log on to, or visit the public version of, the Site. This may include information about the way users arrive at, browse through, and interact with the Site. We may collect this type of technical information through the use of cookies and other means. Cookies are alphanumeric identifiers that we transfer to your computer’s hard drive to enable our systems to recognise your browser. If you want to disable cookies, you may do so by changing the settings on your browser. However, if you do so, you may not be able to use all of the functions on the Site. We use the technical information we collect to have a better understanding of the way people use the Site, to improve the way it works and to personalise it to be more relevant and useful to your particular needs. We may also use this information to assist in making any advertising we display on the Site more personalised and applicable to your interests.</span>
                                    </li>

                                    <li>
                                        <span>Generally, we do not disclose personal information to third parties for them to use for their own purposes. However, some of the circumstances in which we may do this are:</span>

                                        <p>(a) to service providers and other persons working with us to make the Site available or improve or develop its functionality (e.g. we may use a third party supplier to host the Site);</p>

                                        <p>(b) in relation to the proposed purchase or acquisition of our business or assets; or</p>

                                        <p>(c) where required by applicable law or any court, or in response to a legitimate request by a law enforcement agency.</p>
                                    </li>

                                    <li>
                                        <span>Any personal information you provide to us may be stored on the secure servers of our trusted service providers, which may be located outside Singapore. This may involve the transfer of your personal information to countries which have less legal protection for personal information than Singapore.</span>
                                    </li>

                                    <li>
                                        <span>You have the right to request access to and correction of any of the personal information we hold about you. If you would like to exercise these rights, please email us at hello@pixoken.com.</span>
                                    </li>
                                </ol>
                            </li>
                            <li>
                                GENERAL
                                <ol>
                                    <li>
                                        <span><b>Amendments.</b> We may at any time in our sole discretion amend or vary any provision of these Conditions by publication on the Site. If you do not consent to such variation or amendment, you should not use or access the Site. If, following such amendment or variation, you continue to use or access the Site, you shall be deemed to have irrevocably consented to such amendment or variation and to have agreed to be bound thereby.</span>
                                    </li>
                                    <li>
                                        <span><b>Correction of Errors.</b> There may be information on the Site that contains typographical errors, inaccuracies, or omissions that may relate to product descriptions, pricing, promotions, offers, and availability. We reserve the right to correct any errors, inaccuracies, or omissions and to change or update information if any information on the Site is inaccurate at any time without prior notice.</span>
                                    </li>
                                    <li>
                                        <span><b>Illegality and Severability.</b> Each of the provisions of these Conditions is severable from the other(s). If any such provision or part thereof is or becomes invalid, unenforceable, or illegal in any respect, such provision or part thereof shall, to the extent that such term is invalid, be deemed not to form part of these Conditions but the validity, enforceability or legality of the remaining provisions hereunder shall not in any way be affected or impaired thereby.</span>
                                    </li>
                                    <li>
                                        <span><b>Governing Law.</b> These Conditions shall be governed by, and construed in accordance with, the laws of Singapore, and you hereby submit to the exclusive jurisdiction of the courts of Singapore.</span>
                                    </li>
                                </ol>
                            </li>
                        </ol>
                    </div>
                </TncContent>
            </TncColumn>
        </TncSection>
    );
};

export default Tnc;