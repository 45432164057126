import React, { useEffect } from 'react';
import { HomeSection, HomeParallax, HomeContent, HomeTitle, HomeCopy } from './styles';
// import gsap from 'gsap';

const Home = () => {

    useEffect(() => {
        const bg = document.querySelector(".home-bg");
        const obj = document.querySelector(".home-obj");
        const objxs = document.querySelector(".home-obj.xs");
        const parallaxArea= document.querySelector('.home-parallax');

        const parallax = (e) => {
            let bgX= (e.clientX * -0.5 / 30);
            let bgY= (e.clientY * -0.5 / 30);
            let objX= (e.clientX * -2 / 30);
            let objY= (e.clientY * -2 / 30);
            let objXsX= (e.clientX * -2 / 30);
            let objXsY= (e.clientY * -2 / 30);

            bg.style.transform = 'translate3d('+bgX+'px,'+bgY+'px, 0)';
            obj.style.transform = 'translate3d('+objX+'px,'+objY+'px, 0)';
            objxs.style.transform = 'translate3d('+objXsX+'px,'+objXsY+'px, 0)';
        }

        // gsap.to('.parallax-obj', 0.5, {
        //     x: (objMouseX - objectSize.width / 2) / objectSize.width * objectSize.width / 2,
        //     y: (objMouseY - objectSize.height / 2) / objectSize.height * objectSize.height / 2,
        //     ease: 'Power4.easeOut'
        // });

        parallaxArea.addEventListener("mousemove", parallax);

    });

    return (
        <HomeSection className="home-parallax" id="home">
            <HomeParallax>
                <img className="home-bg" src='/images/bg-x.png' alt=''></img>
                <img className="home-obj" src='/images/main-hero.png' alt='Pixoken welcoming future NFT customers to website.'></img>
            </HomeParallax>
            <HomeContent>
                <HomeTitle>
                    <div className='title'>
                        <span className='title--pink'>HEY THERE!</span>
                    </div>
                    <div className='title'>
                        <span className='title--white'>I AM</span>
                        <span className='title--blue'>PIXOKEN</span>
                    </div>
                    <img className='separator' src='/images/dotted-gradient.svg' alt=''></img>
                </HomeTitle>
                
                <img className="home-obj xs" src='/images/main-hero.png' alt='Pixoken welcoming future NFT customers to website.'></img>
                
                <HomeCopy>
                    <h3>And I am here to help you build <span className='title--turquoise'>NFTs</span>, together.</h3>
                    <p>
                    Kick back, grab a coffee, and get comfortable <br className='hide--xs'></br>
                    I'll take you on a journey of discovery<br className='hide--xs'></br>
                    on what we can do for you.<br></br><br></br>

                    Let's start with the basics.
                    </p>
                </HomeCopy>
            </HomeContent>
        </HomeSection>
    );
};

export default Home;