import React from 'react';
import { TeamSection, TeamContent, MemberRow, TeamMember, MemberTitle, MemberDetails, MemberImage, MemberFrame, MemberCopy, Barcode, Code, Description } from './styles';

const Team = () => {

    return (
        <TeamSection className="">
            <TeamContent>
                <MemberRow>
                    <TeamMember>
                        <MemberTitle className="title">
                            <span className='title--pink'>CAPTAIN</span>
                            <span className='title--turquoise'>OVERWATCH</span>
                        </MemberTitle>

                        <MemberDetails>
                            <MemberImage>
                                <MemberFrame>
                                    <img src='/images/character/captain.png' alt='Mini pixoken captain overwatch (project manager) team member' />
                                </MemberFrame>
                            </MemberImage>

                            <MemberCopy>
                                <Barcode>
                                    <svg width="84" height="65" viewBox="0 0 84 65" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <g clipPath="url(#clip0_862_2)">
                                            <path d="M71.5602 0.0100098H78.9202V64.31C78.6626 64.36 78.4021 64.3934 78.1402 64.41C76.3302 64.41 74.5302 64.35 72.7302 64.41C71.7302 64.41 71.5402 64.09 71.5502 63.18C71.5502 51.6 71.5502 40.02 71.5502 28.44V0.0100098H71.5602Z" fill="#ECF4FB"/>
                                            <path d="M15.3601 64.34H8.12012V0.0300293H15.3601V64.34Z" fill="#ECF4FB"/>
                                            <path d="M47.96 0H55.2V64.34H47.96V0Z" fill="#ECF4FB"/>
                                            <path d="M29.7903 64.34H24.1104V0.0300293H29.7903V64.34Z" fill="#ECF4FB"/>
                                            <path d="M33.5703 64.42V0.0499878H39.2703C39.2703 0.419988 39.3403 0.799993 39.3403 1.17999C39.3403 21.8467 39.3403 42.5133 39.3403 63.18C39.3403 64.11 39.1403 64.47 38.1503 64.41C36.6603 64.36 35.1503 64.42 33.5703 64.42Z" fill="#ECF4FB"/>
                                            <path d="M0 0.0100098H4.23999V64.31H0V0.0100098Z" fill="#ECF4FB"/>
                                            <path d="M59.1196 0.0100098H63.3596V64.31H59.0996L59.1196 0.0100098Z" fill="#ECF4FB"/>
                                            <path d="M45.3603 64.37H43.0303V0.0300293H45.3603V64.37Z" fill="#ECF4FB"/>
                                            <path d="M81.5898 0H83.8899V64.33H81.5898V0Z" fill="#ECF4FB"/>
                                            <path d="M18.0596 0.0200195H20.3596V64.31H18.0596V0.0200195Z" fill="#ECF4FB"/>
                                            <path d="M65.6699 0.0100098H67.7699V64.35H65.6699V0.0100098Z" fill="#ECF4FB"/>
                                        </g>
                                        <defs>
                                            <clipPath id="clip0_862_2">
                                                <rect width="83.89" height="64.45" fill="white"/>
                                            </clipPath>
                                        </defs>
                                    </svg>
                                    <Code>
                                        <p>C</p>
                                        <p>O</p>
                                    </Code>
                                </Barcode>

                                <Description>
                                Senior project manager with 14+ years of industry experience leading large-scale transformations.
                                </Description>
                            </MemberCopy>
                        </MemberDetails>
                    </TeamMember>

                    <TeamMember>
                        <MemberTitle className="title">
                            <span className='title--pink'>BRUSH</span>
                            <span className='title--turquoise'>STROKE</span>
                        </MemberTitle>

                        <MemberDetails>
                            <MemberImage>
                                <MemberFrame>
                                    <img src='/images/character/brush.png' alt='Mini pixoken brush stroke (artist) team member' />
                                </MemberFrame>
                            </MemberImage>

                            <MemberCopy>
                                <Barcode>
                                    <svg width="84" height="65" viewBox="0 0 84 65" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <g clipPath="url(#clip0_862_2)">
                                            <path d="M71.5602 0.0100098H78.9202V64.31C78.6626 64.36 78.4021 64.3934 78.1402 64.41C76.3302 64.41 74.5302 64.35 72.7302 64.41C71.7302 64.41 71.5402 64.09 71.5502 63.18C71.5502 51.6 71.5502 40.02 71.5502 28.44V0.0100098H71.5602Z" fill="#ECF4FB"/>
                                            <path d="M15.3601 64.34H8.12012V0.0300293H15.3601V64.34Z" fill="#ECF4FB"/>
                                            <path d="M47.96 0H55.2V64.34H47.96V0Z" fill="#ECF4FB"/>
                                            <path d="M29.7903 64.34H24.1104V0.0300293H29.7903V64.34Z" fill="#ECF4FB"/>
                                            <path d="M33.5703 64.42V0.0499878H39.2703C39.2703 0.419988 39.3403 0.799993 39.3403 1.17999C39.3403 21.8467 39.3403 42.5133 39.3403 63.18C39.3403 64.11 39.1403 64.47 38.1503 64.41C36.6603 64.36 35.1503 64.42 33.5703 64.42Z" fill="#ECF4FB"/>
                                            <path d="M0 0.0100098H4.23999V64.31H0V0.0100098Z" fill="#ECF4FB"/>
                                            <path d="M59.1196 0.0100098H63.3596V64.31H59.0996L59.1196 0.0100098Z" fill="#ECF4FB"/>
                                            <path d="M45.3603 64.37H43.0303V0.0300293H45.3603V64.37Z" fill="#ECF4FB"/>
                                            <path d="M81.5898 0H83.8899V64.33H81.5898V0Z" fill="#ECF4FB"/>
                                            <path d="M18.0596 0.0200195H20.3596V64.31H18.0596V0.0200195Z" fill="#ECF4FB"/>
                                            <path d="M65.6699 0.0100098H67.7699V64.35H65.6699V0.0100098Z" fill="#ECF4FB"/>
                                        </g>
                                        <defs>
                                            <clipPath id="clip0_862_2">
                                                <rect width="83.89" height="64.45" fill="white"/>
                                            </clipPath>
                                        </defs>
                                    </svg>
                                    <Code>
                                        <p>b</p>
                                        <p>s</p>
                                    </Code>
                                </Barcode>

                                <Description>
                                Talented artist with 12 years of experience across branding, design, and illustration
                                </Description>
                            </MemberCopy>
                        </MemberDetails>
                    </TeamMember>
                </MemberRow>

                <MemberRow>
                    <TeamMember>
                        <MemberTitle className="title">
                            <span className='title--pink'>KEYBOARD</span>
                            <span className='title--turquoise'>WARRIOR</span>
                        </MemberTitle>

                        <MemberDetails>
                            <MemberImage>
                                <MemberFrame>
                                    <img src='/images/character/keyboard.png' alt='Mini pixoken keyboard warrior (senior web engineer) team member' />
                                </MemberFrame>
                            </MemberImage>

                            <MemberCopy>
                                <Barcode>
                                    <svg width="84" height="65" viewBox="0 0 84 65" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <g clipPath="url(#clip0_862_2)">
                                            <path d="M71.5602 0.0100098H78.9202V64.31C78.6626 64.36 78.4021 64.3934 78.1402 64.41C76.3302 64.41 74.5302 64.35 72.7302 64.41C71.7302 64.41 71.5402 64.09 71.5502 63.18C71.5502 51.6 71.5502 40.02 71.5502 28.44V0.0100098H71.5602Z" fill="#ECF4FB"/>
                                            <path d="M15.3601 64.34H8.12012V0.0300293H15.3601V64.34Z" fill="#ECF4FB"/>
                                            <path d="M47.96 0H55.2V64.34H47.96V0Z" fill="#ECF4FB"/>
                                            <path d="M29.7903 64.34H24.1104V0.0300293H29.7903V64.34Z" fill="#ECF4FB"/>
                                            <path d="M33.5703 64.42V0.0499878H39.2703C39.2703 0.419988 39.3403 0.799993 39.3403 1.17999C39.3403 21.8467 39.3403 42.5133 39.3403 63.18C39.3403 64.11 39.1403 64.47 38.1503 64.41C36.6603 64.36 35.1503 64.42 33.5703 64.42Z" fill="#ECF4FB"/>
                                            <path d="M0 0.0100098H4.23999V64.31H0V0.0100098Z" fill="#ECF4FB"/>
                                            <path d="M59.1196 0.0100098H63.3596V64.31H59.0996L59.1196 0.0100098Z" fill="#ECF4FB"/>
                                            <path d="M45.3603 64.37H43.0303V0.0300293H45.3603V64.37Z" fill="#ECF4FB"/>
                                            <path d="M81.5898 0H83.8899V64.33H81.5898V0Z" fill="#ECF4FB"/>
                                            <path d="M18.0596 0.0200195H20.3596V64.31H18.0596V0.0200195Z" fill="#ECF4FB"/>
                                            <path d="M65.6699 0.0100098H67.7699V64.35H65.6699V0.0100098Z" fill="#ECF4FB"/>
                                        </g>
                                        <defs>
                                            <clipPath id="clip0_862_2">
                                                <rect width="83.89" height="64.45" fill="white"/>
                                            </clipPath>
                                        </defs>
                                    </svg>
                                    <Code>
                                        <p>k</p>
                                        <p>w</p>
                                    </Code>
                                </Barcode>

                                <Description>
                                Senior web engineer with experience across front end and back end development
                                </Description>
                            </MemberCopy>
                        </MemberDetails>
                    </TeamMember>

                    <TeamMember>
                        <MemberTitle className="title">
                            <span className='title--pink'>CONTRACT</span>
                            <span className='title--turquoise'>ENFORCER</span>
                        </MemberTitle>

                        <MemberDetails>
                            <MemberImage>
                                <MemberFrame>
                                    <img src='/images/character/contract.png' alt='Mini pixoken contract enforcer (smart contract developer) team member' />
                                </MemberFrame>
                            </MemberImage>

                            <MemberCopy>
                                <Barcode>
                                    <svg width="84" height="65" viewBox="0 0 84 65" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <g clipPath="url(#clip0_862_2)">
                                            <path d="M71.5602 0.0100098H78.9202V64.31C78.6626 64.36 78.4021 64.3934 78.1402 64.41C76.3302 64.41 74.5302 64.35 72.7302 64.41C71.7302 64.41 71.5402 64.09 71.5502 63.18C71.5502 51.6 71.5502 40.02 71.5502 28.44V0.0100098H71.5602Z" fill="#ECF4FB"/>
                                            <path d="M15.3601 64.34H8.12012V0.0300293H15.3601V64.34Z" fill="#ECF4FB"/>
                                            <path d="M47.96 0H55.2V64.34H47.96V0Z" fill="#ECF4FB"/>
                                            <path d="M29.7903 64.34H24.1104V0.0300293H29.7903V64.34Z" fill="#ECF4FB"/>
                                            <path d="M33.5703 64.42V0.0499878H39.2703C39.2703 0.419988 39.3403 0.799993 39.3403 1.17999C39.3403 21.8467 39.3403 42.5133 39.3403 63.18C39.3403 64.11 39.1403 64.47 38.1503 64.41C36.6603 64.36 35.1503 64.42 33.5703 64.42Z" fill="#ECF4FB"/>
                                            <path d="M0 0.0100098H4.23999V64.31H0V0.0100098Z" fill="#ECF4FB"/>
                                            <path d="M59.1196 0.0100098H63.3596V64.31H59.0996L59.1196 0.0100098Z" fill="#ECF4FB"/>
                                            <path d="M45.3603 64.37H43.0303V0.0300293H45.3603V64.37Z" fill="#ECF4FB"/>
                                            <path d="M81.5898 0H83.8899V64.33H81.5898V0Z" fill="#ECF4FB"/>
                                            <path d="M18.0596 0.0200195H20.3596V64.31H18.0596V0.0200195Z" fill="#ECF4FB"/>
                                            <path d="M65.6699 0.0100098H67.7699V64.35H65.6699V0.0100098Z" fill="#ECF4FB"/>
                                        </g>
                                        <defs>
                                            <clipPath id="clip0_862_2">
                                                <rect width="83.89" height="64.45" fill="white"/>
                                            </clipPath>
                                        </defs>
                                    </svg>
                                    <Code>
                                        <p>C</p>
                                        <p>e</p>
                                    </Code>
                                </Barcode>

                                <Description>
                                Talented smart contract developer with extensive solidity and smart contract experience
                                </Description>
                            </MemberCopy>
                        </MemberDetails>
                    </TeamMember>

                    <TeamMember className="hide--md">
                        <MemberTitle className="title">
                            <span className='title--pink'>MOUTHPIECE</span>
                            <span className='title--turquoise'>ECHO</span>
                        </MemberTitle>

                        <MemberDetails>
                            <MemberImage>
                                <MemberFrame>
                                    <img src='/images/character/mouthpiece.png' alt='Mini pixoken mouthpiece echo (digital marketing expert) team member' />
                                </MemberFrame>
                            </MemberImage>

                            <MemberCopy>
                                <Barcode>
                                    <svg width="84" height="65" viewBox="0 0 84 65" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <g clipPath="url(#clip0_862_2)">
                                            <path d="M71.5602 0.0100098H78.9202V64.31C78.6626 64.36 78.4021 64.3934 78.1402 64.41C76.3302 64.41 74.5302 64.35 72.7302 64.41C71.7302 64.41 71.5402 64.09 71.5502 63.18C71.5502 51.6 71.5502 40.02 71.5502 28.44V0.0100098H71.5602Z" fill="#ECF4FB"/>
                                            <path d="M15.3601 64.34H8.12012V0.0300293H15.3601V64.34Z" fill="#ECF4FB"/>
                                            <path d="M47.96 0H55.2V64.34H47.96V0Z" fill="#ECF4FB"/>
                                            <path d="M29.7903 64.34H24.1104V0.0300293H29.7903V64.34Z" fill="#ECF4FB"/>
                                            <path d="M33.5703 64.42V0.0499878H39.2703C39.2703 0.419988 39.3403 0.799993 39.3403 1.17999C39.3403 21.8467 39.3403 42.5133 39.3403 63.18C39.3403 64.11 39.1403 64.47 38.1503 64.41C36.6603 64.36 35.1503 64.42 33.5703 64.42Z" fill="#ECF4FB"/>
                                            <path d="M0 0.0100098H4.23999V64.31H0V0.0100098Z" fill="#ECF4FB"/>
                                            <path d="M59.1196 0.0100098H63.3596V64.31H59.0996L59.1196 0.0100098Z" fill="#ECF4FB"/>
                                            <path d="M45.3603 64.37H43.0303V0.0300293H45.3603V64.37Z" fill="#ECF4FB"/>
                                            <path d="M81.5898 0H83.8899V64.33H81.5898V0Z" fill="#ECF4FB"/>
                                            <path d="M18.0596 0.0200195H20.3596V64.31H18.0596V0.0200195Z" fill="#ECF4FB"/>
                                            <path d="M65.6699 0.0100098H67.7699V64.35H65.6699V0.0100098Z" fill="#ECF4FB"/>
                                        </g>
                                        <defs>
                                            <clipPath id="clip0_862_2">
                                                <rect width="83.89" height="64.45" fill="white"/>
                                            </clipPath>
                                        </defs>
                                    </svg>
                                    <Code>
                                        <p>m</p>
                                        <p>e</p>
                                    </Code>
                                </Barcode>

                                <Description>
                                Experienced multi-channel digital marketing expert with 6 years of experience leading digital marketing campaigns
                                </Description>
                            </MemberCopy>
                        </MemberDetails>
                    </TeamMember>
                </MemberRow>

                <MemberRow className="show--md">
                    <TeamMember>
                        <MemberTitle className="title">
                            <span className='title--pink'>MOUTHPIECE</span>
                            <span className='title--turquoise'>ECHO</span>
                        </MemberTitle>

                        <MemberDetails>
                            <MemberImage>
                                <MemberFrame>
                                    <img src='/images/character/mouthpiece.png' alt='Mini pixoken mouthpiece echo (digital marketing expert) team member' />
                                </MemberFrame>
                            </MemberImage>

                            <MemberCopy>
                                <Barcode>
                                    <svg width="84" height="65" viewBox="0 0 84 65" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <g clipPath="url(#clip0_862_2)">
                                            <path d="M71.5602 0.0100098H78.9202V64.31C78.6626 64.36 78.4021 64.3934 78.1402 64.41C76.3302 64.41 74.5302 64.35 72.7302 64.41C71.7302 64.41 71.5402 64.09 71.5502 63.18C71.5502 51.6 71.5502 40.02 71.5502 28.44V0.0100098H71.5602Z" fill="#ECF4FB"/>
                                            <path d="M15.3601 64.34H8.12012V0.0300293H15.3601V64.34Z" fill="#ECF4FB"/>
                                            <path d="M47.96 0H55.2V64.34H47.96V0Z" fill="#ECF4FB"/>
                                            <path d="M29.7903 64.34H24.1104V0.0300293H29.7903V64.34Z" fill="#ECF4FB"/>
                                            <path d="M33.5703 64.42V0.0499878H39.2703C39.2703 0.419988 39.3403 0.799993 39.3403 1.17999C39.3403 21.8467 39.3403 42.5133 39.3403 63.18C39.3403 64.11 39.1403 64.47 38.1503 64.41C36.6603 64.36 35.1503 64.42 33.5703 64.42Z" fill="#ECF4FB"/>
                                            <path d="M0 0.0100098H4.23999V64.31H0V0.0100098Z" fill="#ECF4FB"/>
                                            <path d="M59.1196 0.0100098H63.3596V64.31H59.0996L59.1196 0.0100098Z" fill="#ECF4FB"/>
                                            <path d="M45.3603 64.37H43.0303V0.0300293H45.3603V64.37Z" fill="#ECF4FB"/>
                                            <path d="M81.5898 0H83.8899V64.33H81.5898V0Z" fill="#ECF4FB"/>
                                            <path d="M18.0596 0.0200195H20.3596V64.31H18.0596V0.0200195Z" fill="#ECF4FB"/>
                                            <path d="M65.6699 0.0100098H67.7699V64.35H65.6699V0.0100098Z" fill="#ECF4FB"/>
                                        </g>
                                        <defs>
                                            <clipPath id="clip0_862_2">
                                                <rect width="83.89" height="64.45" fill="white"/>
                                            </clipPath>
                                        </defs>
                                    </svg>
                                    <Code>
                                        <p>m</p>
                                        <p>e</p>
                                    </Code>
                                </Barcode>

                                <Description className="last">
                                Experienced multi-channel digital marketing expert with 6 years of experience leading digital marketing campaigns
                                </Description>
                            </MemberCopy>
                        </MemberDetails>
                    </TeamMember>
                </MemberRow>
            </TeamContent>
        </TeamSection>
    );
};

export default Team;